import React, { useState, useEffect } from "react";
import "../assets/css/Sidebar.css";
import forword from "../assets/images/Chatbot/new-chat.svg";
import axiosInstance from "../utils/axiosInstance";
import { useChat } from "../context/ChatContext";
import signout from "../assets/images/Chatbot/Sign_out_squre.svg";
import { useNavigate } from "react-router-dom";
import mobileHomeIcon from "../assets/images/Chatbot/mobile-home-icon.svg";
import profileIcon from "../assets/images/navbar/profileVector.svg";
import contactusIcon from "../assets/images/navbar/contactusIcon.svg";
import { useCaseContext } from "../context/CaseContext";
import mobileHostoryArrow from "../assets/images/chat/mobileHistoryArrow.svg";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { FaHatWizard } from "react-icons/fa6";

// Shadcn Popover
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";

// ShadCN Tooltip
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../components/ui/tooltip";

const Sidebar = () => {
  const { isOpen, toggleSidebar } = useCaseContext();
  const access_token = sessionStorage.getItem("access_token");
  const {
    handleSelectChat,
    handleNewChat,
    chatRooms,
    setShowPrompts,
    showPrompts,
    fetchChatHistory,
  } = useChat();
  const [userCases, setUserCases] = useState([]);
  const [newChat, setNewChat] = useState(null);
  const [selectedChatId, setSelectedChatId] = useState("");
  const [visibleOptions, setVisibleOptions] = useState(null);

  const navigate = useNavigate();

  const truncateText = (text, length) => {
    if (text.length <= length) {
      return text;
    }
    return text.substring(0, length) + "...";
  };

  const startNewChat = () => {
    setShowPrompts(true);
    handleNewChat(true);
    setSelectedChatId("");
  };
  useEffect(() => {
    console.log("showPrompts updated:", showPrompts);
  }, [showPrompts]);

  const logout = () => {
    localStorage.removeItem("authToken");
    sessionStorage.clear();
    navigate("/login");
  };

  const handleChatButtonClick = (chatId) => {
    handleSelectChat(chatId);
    setNewChat(null);
    setSelectedChatId(chatId);
  };

  const confirmDeleteCase = (caseId) => {
    confirmAlert({
      title: "Confirm To Delete",
      message: "Are you sure you want to delete this chat?",
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDeleteChat(caseId),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleDeleteChat = async (chatId) => {
    try {
      const result = await axiosInstance.delete(
        `/chatapp/chat-room/${chatId}/`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status !== 204) throw new Error("Failed to delete chat room");
      fetchChatHistory();
      // toast
    } catch (err) {
      console.error("Error deleting chat room:", err);
    }
    console.log(`Deleted chat: ${chatId}`);
  };

  useEffect(() => {
    if (chatRooms.length > 0 && selectedChatId === null) {
      setSelectedChatId("");
      setShowPrompts(true);
    }
  }, [chatRooms]);

  return (
    <div
      className={`sidebar ${
        isOpen ? "open" : ""
      } m-0 dark:bg-dark-600 bg-light-600 dark:border-dark-500 dark:border-r-2`}
    >
      <ul className="sidebar-list">
        <div className="mobile-home-button">
          <div className="chatHistory-toggle" onClick={toggleSidebar}>
            <i
              className={`fas ${
                isOpen ? "fa-chevron-left" : "fa-bars"
              } dark:invert-[1]`}
            ></i>
          </div>
          {/* <div
            className="profile-and-home"
            style={{
              display: "flex",
              position: "relative",
              right: "5%",
              gap: "15px",
              flexDirection: "row-reverse",
            }}
          >
            <img
              src={mobileHomeIcon}
              alt=""
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ width: "35px" }}
            />
            <div className="profileImag">
              <img
                src={profileIcon}
                alt=""
                style={{ width: "35px" }}
                onClick={() => {
                  window.location.href = "/user-profile";
                }}
              />
            </div>
          </div> */}
        </div>
        <div className="flex">
          <button
            className="chat-button dark:bg-dark-400 dark:text-gray-300 font-roboto tracking-wide transition-none"
            onClick={startNewChat}
          >
            <div className="icon-box">
              <img src={forword} alt="" className="h-[20px] dark:invert-[1]" />
            </div>
            New Chat
          </button>
        </div>
        <div className="chatroom-mobile-container">
          <h4 className="dark:text-gray-300">History</h4>
          <div className="mobile-all-history">
            {chatRooms.map((chat, index) => (
              <button
                key={index}
                className={`mt-2 ml-0 chat-button1 ${
                  chat.id === selectedChatId ? "selected" : ""
                } dark:bg-transparent dark:text-gray-300`}
                onClick={() => handleChatButtonClick(chat.id)}
              >
                <div
                  className="mobile-history-side-content"
                  onClick={toggleSidebar}
                >
                  <div className="mobile-chat-name">
                    {chat.chat_name && truncateText(chat.chat_name, 19)}
                  </div>
                  <div className="mobilehistoryimage">
                    <img
                      src={mobileHostoryArrow}
                      alt=""
                      className="dark:invert-[1] scale-50"
                    />
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        <div className="laptop-history" style={{ color: "#2C5966" }}>
          <h6
            className="mb-0 ml-2 chat-section font-roboto text-sm font-semibold dark:text-gray-300 tracking-wide"
            style={{ marginTop: "25px" }}
          >
            Previous Chats
          </h6>
          <div className="all-history">
            {chatRooms.map((chat, index) => (
              <div className="flex items-center pr-3 ">
                <TooltipProvider>
                  <Tooltip className="">
                    <TooltipTrigger className="w-full">
                      <div key={index} className="chat-button-containe ">
                        <button
                          className={`chat-button1 mx-3 hover:bg-gray-300 hover:dark:bg-sh-dark-500 mt-2 ml-0 dark:bg-dark-600 bg-light-600 dark:text-gray-300 font-roboto font-light transition-none ${
                            chat.id === selectedChatId
                              ? "bg-gray-300 dark:bg-sh-dark-500"
                              : ""
                          }`}
                          onClick={() => {
                            handleChatButtonClick(chat.id);
                            console.log(chat);
                          }}
                        >
                          {chat.chat_name && truncateText(chat.chat_name, 20)}

                          {/* <button
                          className="options-toggle-button dark:text-gray-300"
                          onClick={() =>
                            setVisibleOptions(
                              visibleOptions === chat.id ? null : chat.id
                            )
                          }
                        >
                          ...
                        </button> */}
                        </button>
                        {/* {visibleOptions === chat.id && ( */}
                        {/* // <div className="chat-options">
                        //   <button
                        //     onClick={(e) => {
                        //       e.stopPropagation();
                        //       confirmDeleteCase(chat.id);
                        //     }}
                        //     className="option-button dark:bg-dark-400 dark:text-gray-200"
                        //   >
                        //     Delete
                        //   </button> */}
                        {/* <button
                            onClick={() => console.log("Share chat:", chat.id)}
                            className="option-button"
                          >
                            Share
                          </button>
                          <button
                            onClick={() => console.log("Rename chat:", chat.id)}
                            className="option-button"
                          >
                            Rename
                          </button>
                          <button
                            onClick={() =>
                              console.log("Archive chat:", chat.id)
                            }
                            className="option-button"
                          >
                            Archive
                          </button> */}
                        {/* </div> */}
                        {/* // )} */}
                      </div>
                    </TooltipTrigger>
                    <TooltipContent sideOffset={-3} align="start">
                      {chat.chat_name && truncateText(chat.chat_name, 70)}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
                <Popover>
                  <PopoverTrigger
                    onClick={() =>
                      setVisibleOptions(
                        visibleOptions === chat.id ? null : chat.id
                      )
                    }
                  >
                    ...
                  </PopoverTrigger>
                  <PopoverContent sideOffset={0} className="p-0 px-4 w-fit">
                    <button
                      onClick={(e) => {
                        e.stopPropagation();
                        confirmDeleteCase(chat.id);
                      }}
                      className="p-0 dark:text-gray-200 text-sm"
                    >
                      Delete
                    </button>
                  </PopoverContent>
                </Popover>
              </div>
            ))}
          </div>
        </div>
        {/* <div className="laptop-logout-container">
          <div className="horizontal-line"></div>
          <button className="laptop-sidebar-logout-button" onClick={logout}>
            <div className="text">Logout</div>
            <div className="logout-img">
              <img src={signout} alt="sign out" />
            </div>
          </button>
        </div> */}
      </ul>
      <div className="dark:text-gray-300 font-roboto tracking-widest text-2xl flex justify-center items-center mt-11 gap-2">
        <FaHatWizard />
        <p>LaWiz</p>
      </div>
      {/* <div className="logout-container">
        <div className="horizontal-line"></div>
        <button className="sidebar-logout-button" onClick={logout}>
          <div className="text">Log Out</div>
          <div className="logout-img">
            <img src={signout} alt="sign out" />
          </div>
        </button>
        <div className="profileImag">
          <span
            style={{
              color: "#0d0d0d",
              fontSize: "18px",
              fontWeight: "500",
              margin: "10px",
            }}
          >
            Contact Us
          </span>
          <img src={contactusIcon} alt="" />
        </div>
      </div> */}
    </div>
  );
};

export default Sidebar;
