import React from "react";
// import Navbar from "./Navbar";
import "../assets/css/PrivacyPolicy.css";
import privacyIcon from "../assets/images/comingSoon/privacy-icon.svg";

export default function PrivacyPolicy() {
  return (
    <>
      {/* <Navbar /> */}
      <div className="privacy-main-contain dark:bg-dark-600 bg-light-600">
        <div className="privacy-header mt-[150px] mb-8">
          <h3 className="text-gray-700 dark:text-gray-300">
            <span>Privacy </span>Policy
          </h3>
          <p className="text-gray-900 border p-3 dark:text-gray-300">
            Last updated: July 7, 2024
          </p>
        </div>
        <div className="privacy-contain-container dark:bg-dark-400 dark:text-gray-200 tracking-wide leading-relaxed">
          <img src={privacyIcon} alt="" />
          <div className="privacy-main-section">
            <h1>1. Introduction</h1>
            <p className="mb-10">
              Welcome to CaseMinister, a product of Advanced Technology and
              Research Innovation Group. We are committed to protecting your
              privacy and ensuring your personal information is handled
              responsibly. This Privacy Policy describes how we collect, use,
              and share your personal information in compliance with the
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules, 2011
              under the Information Technology Act, 2000, and other applicable
              Indian laws.
            </p>
            <h1>2. Information Collection</h1>
            <p className="mb-4">
              We collect various types of information to provide and improve our
              Service to you. This includes:
            </p>
            <p className="mb-4">
              <span>2.1</span> Consent By using our Service, you consent to the
              collection, use, and sharing of your personal information as
              described in this Privacy Policy. If you do not agree with this
              policy, please do not use our Service.
            </p>
            <p className="mb-4">
              <span>2.2</span> Personal Data: We may collect personal data,
              including but not limited to passwords, financial information,
              health information, and biometric data. This data will be
              processed with utmost care and in accordance with Indian data
              protection laws. We will obtain your explicit consent before
              collecting and processing such data.
            </p>
            <p>Personal Identification Information:</p>
            <ul className="mb-6">
              <li>Name</li>
              <li>Email Address</li>
              <li>Phone Number</li>
              <li>Professional details</li>
            </ul>
            <p>Usage Data:</p>
            <ul className="mb-6">
              <li>IP address</li>
              <li>Browser type and version</li>
              <li>Pages visited</li>
              <li>Time spent on pages</li>
              <li>Referring website addresses</li>
            </ul>
            <p className="mb-10">
              Cookies and Tracking Technologies: We use cookies and similar
              tracking technologies to track activity on our Service and hold
              certain information.
            </p>
            <h1>3. Use Of Information</h1>
            <p className="mb-4">
              We use the collected information for various purposes: 
            </p>
            <ul className="mb-10">
              <li>To provide and maintain our Service </li>
              <li>To notify you about changes to our Service </li>
              <li>
                To allow you to participate in interactive features of our
                Service 
              </li>
              <li>To provide customer support </li>
            </ul>
            <h1>4. Sharing of Information </h1>
            <p className="mb-4">
              We may share your personal information in the following
              situations: 
            </p>
            <ul className="mb-10">
              <li>
                With Service Providers: We may share your information with
                third-party service providers who perform services on our
                behalf, such as hosting, data analysis, customer service, and
                marketing assistance. 
              </li>
              <li>
                For Legal Reasons: We may disclose your information if required
                to do so by law or in response to valid requests by public
                authorities (e.g., a court or government agency). 
              </li>
              <li>
                Business Transfers: In connection with, or during negotiations
                of, any merger, sale of company assets, financing, or
                acquisition of all or a portion of our business to another
                company. 
              </li>
            </ul>
            <h1>5. Data Security</h1>
            <p className="mb-4">
              We implement appropriate technical and organizational measures to
              protect your personal information against unauthorized access,
              alteration, disclosure, or destruction. These measures include: 
            </p>
            <ul className="mb-10">
              <li>Encryption of data in transit and at rest </li>
              <li>Regular security assessments and audits </li>
              <li>Access controls and authentication mechanisms </li>
            </ul>
            <h1>6. User Rights</h1>
            <p className="mb-4">You have the right to: </p>
            <ul className="mb-6">
              <li>
                Access Your Data: Request access to your personal information we
                hold.
              </li>
              <li>
                Correct Your Data: Request correction of any inaccurate or
                incomplete data.
              </li>
              <li>
                Delete Your Data: Request deletion of your personal information,
                subject to certain exceptions.
              </li>
              <li>
                Data Portability: Request a copy of your personal data in a
                structured, commonly used, and machine-readable format.
              </li>
              <li>
                Withdraw Consent: Withdraw your consent for data processing at
                any time, where consent is the basis for processing. 
              </li>
            </ul>
            <p className="mb-10">
              To exercise these rights, please contact us at{" "}
              <span style={{ fontWeight: "700" }}>Info@Caseminister.com.</span>{" "}
            </p>
            <h1>7. Cookies and Tracking Technologies</h1>
            <p className="mb-10">
              We use cookies and similar tracking technologies to track activity
              on our Service and hold certain information. You can instruct your
              browser to refuse all cookies or to indicate when a cookie is
              being sent. However, if you do not accept cookies, you may not be
              able to use some portions of our Service. 
            </p>
            <h1>8. User Consent Clause</h1>
            <p className="mb-10">
              By submitting your case information to CaseMinister, you
              explicitly consent to CaseMinister accessing your court records
              available publicly for the purpose of providing and improving our
              services. You acknowledge and agree that the information accessed
              from public court records will be used in accordance with our
              Privacy Policy and Terms and Conditions. This includes, but is not
              limited to, sourcing data from eCourts, Delhi High Court, and
              other public legal records.
            </p>
            <h1>9. Disclaimer</h1>
            <p className="mb-4">
              The information provided by CaseMinister is sourced from public
              court records, including but not limited to the eCourts and Delhi
              High Court websites. Users are advised to verify the accuracy of
              the data directly with the respective courts or through official
              records. The data provided by CaseMinister is not intended for use
              as legal evidence and should not be relied upon as such.
            </p>
            <p className="mb-4">
              Neither the Courts concerned nor the National Informatics Centre
              (NIC) nor the e-Committee is responsible for any data inaccuracy
              or delay in the updation of the data on their websites. We do not
              accept any responsibility or liability for any damage or loss
              arising from the direct or indirect use of the information
              provided on CaseMinister. If any errors or omissions are found, we
              would appreciate them being brought to our notice so that
              corrections can be made. 
            </p>
            <h1>10. Changes to This Privacy Policy</h1>
            <p className="mb-4">
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page
              and updating the "Last updated" date at the top of this Privacy
              Policy.
            </p>
            <p className="mb-10">
              You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they
              are posted on this page.
            </p>
            <h1>11. Contact Information</h1>
            <p className="mb-4">
              If you have any questions or concerns about this Privacy Policy or
              our data practices, please contact us at: 
            </p>
            <p className="mb-6" style={{ fontWeight: "800" }}>
              Advanced Technology and Research Innovation Group <hr /> Suite 01,
              64 Nile Street, London, N1 7SR 
              <hr />
              Email: Info@Caseminister.com 
              <hr />
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
