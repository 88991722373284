// import React from 'react';
// import '../assets/css/about.css'
// import Navbar from './Navbar';
// import topimage from '../assets/images/profile/uppper-side.svg'
// import tushar from '../assets/images/profile/tushar.svg'
// import about1 from '../assets/images/profile/about1.svg'
// import about2 from '../assets/images/profile/about2.svg'
// import about3 from '../assets/images/profile/about3.svg'

// const Aboutus = () => {
//   return (
//     <>
//       <Navbar />
//       <div className="page-container">
//         <div className="header2">
//           <h1>Pioneering solutions that</h1>
//           <h1>redefine the legal landscape</h1>
//           <img src={topimage} className="top-image" alt="Top Image" />
//         </div>
//         <div className="content1">
//           <div className="story-section">
//             <h2> <span style={{ color: '#5d54f1' }}>Our </span>Story</h2>
//             <p>CaseMinister was founded on the belief that justice should
//               be a right for everyone, not just a privilege for a few. By
//               dismantling the barriers within the legal system, we're
//               making legal support more  <span style={{ fontWeight: '700' }}>transparent, efficient, and fair.</span></p>
//           </div>
//           <div className="timeline">
//             <div className="timeline-item">
//               <div className="dot"></div>
//               <div className="text">
//                 <h3><span style={{ fontWeight: '800' }}> 2023</span> Founded</h3>
//                 <p>Founder: Tushar Atri</p>
//                 {/* <img src={about1} alt="" /> */}
//                 <p>Vision: Democratize access to justice through AI</p>
//               </div>
//             </div>
//             <div className="timeline-item">
//               <div className="dot"></div>
//               <div className="text">
//                 {/* <img src={about2} alt="" /> */}
//                 <h3> <span style={{ fontWeight: '800' }}>100k+</span> Lawyers</h3>
//                 <p>Endorsed by Leading Bar Council and Associations Across India</p>
//               </div>
//             </div>
//             <div className="timeline-item">
//               <div className="dot"></div>
//               <div className="text">
//                 {/* <img src={about3} alt="" /> */}
//                 <h3><span style={{ fontWeight: '800' }}> 300k+</span> Lawyers</h3>
//                 <p>Endorsed by Leading Bar Council and Associations Across India</p>
//               </div>
//             </div>
//             <svg className="graph-line" viewBox="0 0 100 100" preserveAspectRatio="none">
//               <path d="M0,100 Q30,90 50,50 T100,0" vectorEffect="non-scaling-stroke" />
//             </svg>
//           </div>
//           <div className="mission-section" style={{ position: 'relative' }}>
//             <h2>Our Mission</h2>
//             <blockquote>
//               <span style={{
//                 fontSize: '3em',
//                 fontWeight: 'bold',
//                 verticalAlign: 'top',
//                 position: 'absolute',
//                 top: '25%',
//                 left: '20%'
//               }}>“</span>
//               Empowering legal professionals, businesses, and individuals
//               with innovative AI-powered solutions to transform the Indian
//               Justice Delivery System
//               <span style={{
//                 fontSize: '3em',
//                 fontWeight: 'bold',
//                 verticalAlign: 'bottom',
//                 position: 'absolute',
//                 right: '35%',
//                 top: '60%',

//               }}>”</span>
//             </blockquote>
//           </div>
//         </div>
//         <div className="founder-section">
//           <div className="about-text-content">
//             <h2><span style={{ color: '#5d54f1' }}>Our</span> Founder</h2>
//             <p>Tushar Atri began his career in <strong>litigation and in-house legal roles</strong> across startups and companies before transitioning to legal tech at a multinational corporation. Seeking to broaden his expertise, he earned a master's degree from the <strong>University of Exeter</strong>, gaining international experience. After working in the UK, Tushar returned to India to leverage his global insights and legal experience to create <a href="#">Advanced Technology and Research Innovation Group’s legal tech platform—CaseMinister</a>.</p>
//           </div>
//           <div className="image-content">
//             <img src={tushar} alt="Tushar Atri at University of Exeter" />
//           </div>
//         </div>
//       </div>
//     </>

//   );
// };

// export default Aboutus;

import React from "react";
import Footer from "./Footer";
import { Link } from "react-router-dom";

import OurStory from "../assets/images/aboutus/OurStory.svg";
import OurMission from "../assets/images/aboutus/OurMission.png";
import WhatWeDo from "../assets/images/aboutus/WhatWeDo.svg";
// import ProfileAvatar from "../assets/images/aboutus/ProfileAvatar.png";

// import { BsLinkedin } from "react-icons/bs";

const Aboutus = () => {
  return (
    <div className="pt-16 bg-light-600 dark:bg-dark-600">
      {/* Header */}
      <div className="bg-[url('/src/assets/images/aboutus/AboutUsBG.png')] max-md:bg-center max-md:px-4 px-24 pb-16 pt-20 bg-no-repeat">
        <div class="flex flex-col items-start gap-5">
          <h1 className="max-md:text-xl font-mulish font-semibold max-w-xl text-4xl leading-[54px] text-[#E2E8F0]">
            Empowering Legal Professionals <br /> with AI-Powered Solutions
          </h1>
          <p className="text-[#E2E8F0] font-mulish font-medium text-xl max-md:text-base leading-9 max-w-lg">
            Revolutionizing legal workflows with innovative AI tools to simplify
            processes, save time, and enhance productivity
          </p>
          <Link
            to="/contactus"
            className="rounded-md max-md:px-4 max-md:text-sm max-md:py-1.5 mt-4 px-7 py-2 bg-[#F5F5F5] text-black font-mulish font-bold"
          >
            Contact Us
          </Link>
        </div>
      </div>
      {/* Story Section */}
      <div className="py-20 flex flex-col gap-24 dark:bg-dark-600">
        <div className="flex py-10 max-md:px-4 justify-center w-full gap-16 bg-[#fefefe] dark:bg-dark-400 pl-10 max-md:flex-col">
          <img src={OurMission} alt="Our mission" />
          <div className="flex flex-col gap-6 justify-center">
            <h3 className="font-mulish font-extrabold text-3xl max-md:text-2xl">
              Our Mission
            </h3>
            <ul className="list-disc font-raleway font-medium leading-10 text-xl max-md:text-lg">
              <li className="mb-2 ml-5 pl-3">
                Simplify complex legal processes with AI-driven tools.
              </li>
              <li className="mb-2 ml-5 pl-3">
                Empower businesses and individuals with innovative solutions.
              </li>
              <li className="mb-2 ml-5 pl-3">
                Enhance accessibility and transparency in legal systems.
              </li>
              <li className="ml-5 pl-3">
                Improve efficiency and accuracy in legal workflows through
                cutting-edge technology.
              </li>
            </ul>
          </div>
        </div>
        <div className="px-10 flex py-10 max-md:px-4 justify-center w-full gap-10 bg-[#fefefe] dark:bg-dark-400 max-md:flex-col">
          <div className="flex flex-col gap-6 justify-center max-md:order-2 ">
            <h3 className="font-mulish font-extrabold text-3xl max-md:text-2xl">
              Our Story
            </h3>
            <ul className="list-disc font-raleway font-medium leading-10 text-xl max-md:text-lg">
              <li className="mb-2 ml-5 pl-3">
                CaseMinister was established to streamline legal workflows.
              </li>
              <li className="mb-2 ml-5 pl-3">
                Launched AI-powered chatbots to provide real-time legal
                assistance.
              </li>
              <li className="mb-2 ml-5 pl-3">
                Introduced advanced analytics tools to deliver data-driven
                insights for better legal decision-making.
              </li>
              <li className="ml-5 pl-3">
                The company expanded globally, establishing offices in London
                and Gurugram.
              </li>
            </ul>
          </div>
          <img src={OurStory} alt="our story" className="max-md:order-1" />
        </div>
        <div className="flex py-20 max-md:px-4 justify-center w-full gap-16 bg-[#fefefe] dark:bg-dark-400 pl-10 max-md:flex-col">
          <img src={WhatWeDo} alt="What we do" />
          <div className="flex flex-col gap-6 justify-center">
            <h3 className="font-mulish font-extrabold text-3xl max-md:text-2xl">
              What We Do
            </h3>
            <ul className="list-disc font-raleway font-medium leading-10 text-xl max-md:text-lg">
              <li className="mb-2 ml-5 pl-3">
                <span className="font-bold">AI Chatbot:</span> Provides
                Real-time assistance.
              </li>
              <li className="mb-2 ml-5 pl-3">
                <span className="font-bold">Case Tracking:</span> Stay informed
                with instant updates on ongoing cases.
              </li>
              <li className="mb-2 ml-5 pl-3">
                <span className="font-bold">Document Analysis:</span> Emphasizes
                automation for reviews and insights.
              </li>
              <li className="ml-5 pl-3">
                <span className="font-bold">Judgement Search:</span>{" "}
                Effortlessly search case details, including petitioner,
                respondent, and trial outcomes.
              </li>
            </ul>
          </div>
        </div>
      </div>
      {/* Founder Section */}
      <div></div>
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Aboutus;
