import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { MdOutlineArrowDropDown } from "react-icons/md";

const transition = {
  type: "",
  mass: 0.5,
  damping: 11.5,
  stiffness: 100,
  restDelta: 0.001,
  restSpeed: 0.001,
};

export const MenuItem = ({ setActive, active, item, children, arrow }) => {
  return (
    <div onMouseEnter={() => setActive(item)} className="relative z-20">
      <motion.p
        transition={{ duration: 0.3 }}
        className="flex items-center cursor-pointer font-semibold lg:text-md text-black dark:text-white hover:text-black/60 hover:dark:text-white/70"
      >
        {item}
        {arrow && <MdOutlineArrowDropDown />}
      </motion.p>
      {active !== null && (
        <motion.div
          initial={{ opacity: 0, scale: 0.85, y: 10 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={transition}
        >
          {active === item && (
            <div className="absolute top-[calc(100%_+_0.5rem)] bg-light-600 dark:bg-dark-600 left-1/2 transform -translate-x-1/2 pt-4">
              <motion.div
                transition={transition}
                // layoutId ensures smooth animation
                layoutId="active"
                className="bg-light-600 dark:bg-dark-600 rounded-2xl overflow-hidden border-none border-light-600 dark:border-dark-600 shadow-xl"
              >
                <motion.div
                  // layout ensures smooth animation
                  layout
                  className="w-max h-full p-4 bg-slate-50 dark:bg-dark-600"
                >
                  {children}
                </motion.div>
              </motion.div>
            </div>
          )}
        </motion.div>
      )}
    </div>
  );
};

export const Menu = ({ setActive, children }) => {
  return (
    <nav
      // resets the state
      onMouseLeave={() => setActive(null)}
      className="relative dark:bg-dark-600 dark:border-white/[0.2] bg-light-600 dark:shadow-white flex justify-between items-center space-x-4 max-lg:px-5 px-20 py-3"
    >
      {children}
    </nav>
  );
};

export const ProductItem = ({ title, description, href, src }) => {
  return (
    <a
      href={href}
      className="flex space-x-3 hover:dark:bg-dark-400 hover:bg-gray-200 rounded-md px-3 py-3"
    >
      <img
        src={src}
        width={140}
        height={70}
        alt={title}
        className="flex-shrink-0 rounded-md shadow-2xl max-lg:w-[100px]"
      />
      <div>
        <h4 className="max-lg:text-lg text-xl font-bold mb-1 text-black dark:text-white">
          {title}
        </h4>
        <p className="text-neutral-700 max-lg:text-xs text-sm max-w-[14rem] dark:text-gray-400">
          {description}
        </p>
      </div>
    </a>
  );
};

export const HoveredLink = ({ children, ...rest }) => {
  return (
    <Link
      {...rest}
      className="text-black text-md dark:text-white hover:opacity-[0.7] hover:no-underline"
    >
      {children}
    </Link>
  );
};
