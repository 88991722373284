import copy from "copy-to-clipboard";
import React, { useState } from "react";

import { GoLink } from "react-icons/go";
import { MdContentCopy } from "react-icons/md";
import { IoMdCheckmark } from "react-icons/io";

import Linkedin from "../../../assets/images/share/Linkedin.png";
import Facebook from "../../../assets/images/share/Facebook.png";
import Twitter from "../../../assets/images/share/X.png";
import Whatsapp from "../../../assets/images/share/Whatsapp.png";

const Share = () => {
  const [copyClicked, setCopyClicked] = useState(false);

  const handleCopy = () => {
    copy("https://caseminister.com/");
    setCopyClicked(true);
    const timeoutId = setTimeout(() => {
      setCopyClicked(false);
    }, 2000);
    return () => clearTimeout(timeoutId);
  };

  return (
    <div className="pb-4 relative dark:bg-dark-600">
      <div className="mx-auto mt-20 w-[600px] h-[400px] bg-[#fefefe] dark:bg-dark-450 p-6 shadow-md flex flex-col gap-6">
        <div>
          <h6 className="font-mulish font-semibold text-lg">
            Share Case Minister with your friends
          </h6>
        </div>
        <div className="flex flex-col gap-2">
          <p className="font-semibold font-mulish">Copy Link</p>
          <div className="flex items-center gap-2 justify-between px-4 py-1.5 bg-[#f7f7f7] dark:bg-dark-600 rounded-sm">
            <div className="flex gap-3 items-center">
              <GoLink className="text-sm" />
              <p className="opacity-70 font-mulish font-medium">
                https://caseminister.com
              </p>
            </div>
            {copyClicked ? (
              <IoMdCheckmark className="cursor-pointer" />
            ) : (
              <MdContentCopy className="cursor-pointer" onClick={handleCopy} />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 ">
          <p className="font-semibold font-mulish">
            Share on your Social Media
          </p>
          <div className="flex gap-6 bg-[#f7f7f7] dark:bg-dark-600 p-4 rounded-md">
            <a
              href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fcaseminister.com%2F&title=🌟%20Discover%20Legal%20Solutions%20at%20CaseMinister!%20🌟&summary=Navigating%20legal%20proceedings%20can%20be%20tough.%20We%E2%80%99re%20here%20to%20help!%0AVisit%20https%3A%2F%2Fcaseminister.com%20for:%0A*%20Clear%20guides%20on%20legal%20processes.%0A*%20Expert%20tips.%0A*%20Tools%20to%20understand%20your%20rights.%0AEmpower%20yourself"
              className="bg-white dark:bg-gray-700 p-6 rounded-md flex justify-center items-center hover:bg-[#f8fffa] dark:hover:opacity-80"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Linkedin} alt="linkedin logo" width={"80%"} />
            </a>
            <a
              href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fcaseminister.com%2F&quote=🌟%20Discover%20Legal%20Solutions%20at%20CaseMinister%20🌟%0A%0ANavigating%20legal%20proceedings%20can%20be%20tough,%20but%20we%E2%80%99re%20here%20to%20help!%0AVisit%20https%3A%2F%2Fcaseminister.com%20for:%0A*%20Clear%20guides%20on%20legal%20processes.%0A*%20Expert%20tips%20and%20insights.%0A*%20Tools%20to%20understand%20your%20rights.%0A%0AEmpower%20yourself%20with%20the%20knowledge%20you%20need!%0ACheck%20us%20out%20today!📞Questions?%20Feel%20free%20to%20reachout"
              className="bg-white dark:bg-gray-700 p-6 rounded-md flex justify-center items-center hover:bg-[#f8fffa] dark:hover:opacity-80"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} alt="facebook logo" width={"80%"} />
            </a>
            <a
              href="https://x.com/intent/post?lang=en&text=🌟%20Discover%20Legal%20Solutions%20at%20CaseMinister!%20🌟%0A%0ANavigating%20legal%20issues?%20We%E2%80%99re%20here%20to%20help!%20Visit%20https://caseminister.com/%20for:%0A%0A*%20Clear%20guides%20on%20legal%20processes%0A*%20Expert%20tips%20and%20insights%0A*%20Tools%20to%20understand%20your%20rights%0A%0AEmpower%20yourself%20today!%20📞%20Questions?%20Reach%20out"
              className="bg-white dark:bg-gray-700 p-6 rounded-md flex justify-center items-center hover:bg-[#f8fffa] dark:hover:opacity-80"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Twitter} alt="x logo" width={"80%"} />
            </a>
            <a
              href="https://api.whatsapp.com/send/?text=%F0%9F%8C%9F%20Discover%20Legal%20Solutions%20at%20CaseMinister%20%F0%9F%8C%9F%0A%0ANavigating%20legal%20proceedings%20can%20be%20tough%2C%20but%20we%E2%80%99re%20here%20to%20help%21%20Visit%20https%3A//caseminister.com/%20for%3A%0A%0A%E2%80%A2%20Clear%20guides%20on%20legal%20processes%0A%E2%80%A2%20Expert%20tips%20and%20insights%0A%E2%80%A2%20Tools%20to%20understand%20your%20rights%0A%0AEmpower%20yourself%20with%20the%20knowledge%20you%20need!%20Check%20us%20out%20today%20at%20https%3A//caseminister.com/%21%0A%F0%9F%93%9E%20Questions?%20Feel%20free%20to%20reachout"
              className="bg-white dark:bg-gray-700 p-6 rounded-md flex justify-center items-cente hover:bg-[#f8fffa] dark:hover:opacity-80"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Whatsapp} alt="whatsapp logo" width={"80%"} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Share;
