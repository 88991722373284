// React Utils
import React from "react";

// ShadCN Tabs
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";

// Icons
import { FaAngleRight } from "react-icons/fa6";
import { PiSealCheckFill } from "react-icons/pi";
import { PiStarFourFill } from "react-icons/pi";
import PricingDiscount from "../../assets/images/pricing/PricingDiscount.svg";
import ArrowPricing from "../../assets/images/pricing/ArrowPricing.svg";

// Get Width
import { useWindowWidth } from "@react-hook/window-size";

// Axios Util
import axiosInstance from "../../utils/axiosInstance";

// Razorpay Utils
import { useRazorpay } from "react-razorpay";

const Pricing = () => {
  const { Razorpay } = useRazorpay();

  const access_token = sessionStorage.getItem("access_token"); // Authorization Token

  const onlyWidth = useWindowWidth(); // Getting Sceen Width for Easy Mobile Changes

  const handlePaymentCompletion = (payment_id, subscription_id, signature) => {
    try {
      const response = axiosInstance.post(
        "razorpay/order/complete/",
        {
          payment_id,
          subscription_id,
          signature,
          // amount,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const handlePayment = async (pricing, plan) => {
    console.log(plan, "PLAN");
    try {
      const response = await axiosInstance.post(
        "razorpay/order/create/",
        {
          plan, // Plan
          currency: "INR", // Currency in INR.
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const { id } = response.data.data;

      const options = {
        key: "rzp_test_CwuWrPMjeZXkyB",
        // name: "Test Company",
        currency: "INR",
        // description: "Test Transaction",
        subscription_id: id, // Generate subscription_id on server
        handler: (response) => {
          console.log(response);
          //   alert("Payment Successful!");
          handlePaymentCompletion(
            response.razorpay_payment_id,
            response.razorpay_subscription_id,
            response.razorpay_signature
            // pricing * 100
          );
        },
        prefill: {
          name: "John Doe",
          email: "john.doe@example.com",
          contact: "9999999999",
        },
        theme: {
          color: "#F37254",
        },
      };

      const razorpayInstance = new Razorpay(options);
      razorpayInstance.open();
      console.log(response);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  return (
    <div className="pt-16">
      <div className="mt-10 flex flex-col gap-12 items-center">
        {/* Heading Section */}
        <div className="text-center flex flex-col gap-5">
          <h1 className="font-mulish font-bold text-4xl">Choose Your Plan</h1>
          <p className="font-mulish font-semibold text-xl max-w-[700px]">
            Unlock premium feature like Case Tracking, Document Analysis with
            our affordable subscriptions.
          </p>
        </div>
        {/* Tabs Section */}
        <div>
          <Tabs
            defaultValue="Monthly"
            className="flex flex-col gap-12 items-center"
          >
            <TabsList className="relative w-[200px] mb-4 bg-white justify-evenly rounded-3xl px-2 py-[22px] shadow-md">
              <TabsTrigger
                value="Monthly"
                className="rounded-2xl px-4 data-[state=active]:bg-[#087b3E] data-[state=active]:text-white"
              >
                Monthly
              </TabsTrigger>
              <TabsTrigger
                value="Yearly"
                className="rounded-2xl px-4 data-[state=active]:bg-[#087b3E] data-[state=active]:text-white"
              >
                Yearly
              </TabsTrigger>
              <img
                className="absolute left-44 top-6 max-sm:hidden"
                src={PricingDiscount}
                alt="yearly discount"
              />
              <img
                className="absolute left-36 top-8 sm:hidden"
                src={ArrowPricing}
                alt="yearly discount"
              />
            </TabsList>
            <TabsContent value="Monthly">
              <div className="flex gap-10 max-[769px]:flex-wrap justify-center px-4">
                <Card
                  handlePayment={handlePayment}
                  borderColor="border-[#D70202]"
                  hoverColor="hover:bg-[#D70202]"
                  subName="Case Tracker"
                  pricing={499}
                  nonDiscounted={999}
                  time="month"
                  middle="hidden"
                  point1="50 cases"
                  point2="Basic Tracking"
                  point3="Notifications"
                  point4="Updates"
                  point5="Case Specific Chatbot"
                  point6="Multi-Language Support"
                  plan="tracker"
                />
                <Card
                  handlePayment={handlePayment}
                  borderColor="border-[#700BB2]"
                  hoverColor="hover:bg-[#700BB2]"
                  subName="Professional"
                  pricing={1199}
                  nonDiscounted={1999}
                  time="month"
                  middle="hidden"
                  point1="Unlimited Cases"
                  point2="500 Chatbot Queries"
                  point3="Basic Reporting"
                  point4="Case Tracking"
                  point5="Case Specific Chatbot"
                  point6="Multi-Language Support"
                  plan="pro"
                />
                <Card
                  handlePayment={handlePayment}
                  borderColor="border-[#F58A0E]"
                  hoverColor="hover:bg-[#F58A0E]"
                  subName="Enterprise"
                  pricing={4999}
                  time="month"
                  middle="hidden"
                  point1="Unlimited Cases"
                  point2="Unlimited Chatbot Queries"
                  point3="Advance Reporting"
                  point4="Case Analytics"
                  point5="Collaboration Tools"
                  point6="Multi-Language Support"
                  plan="enterprises"
                />
              </div>
            </TabsContent>
            <TabsContent value="Yearly">
              <div className="flex gap-10 max-[769px]:flex-wrap justify-center px-4">
                <Card
                  handlePayment={handlePayment}
                  borderColor="border-[#D70202]"
                  hoverColor="hover:bg-[#D70202]"
                  subName="Case Tracker"
                  pricing={4200}
                  nonDiscounted={5988}
                  time={"year"}
                  middle="hidden"
                  point1="50 cases"
                  point2="Basic Tracking"
                  point3="Notifications"
                  point4="Updates"
                  point5="Case Specific Chatbot"
                  point6="Multi-Language Support"
                  plan="tracker_yearly"
                />
                <Card
                  handlePayment={handlePayment}
                  borderColor="border-[#700BB2]"
                  hoverColor="hover:bg-[#700BB2]"
                  subName="Professional"
                  pricing={10000}
                  nonDiscounted={14388}
                  time={"year"}
                  middle="hidden"
                  point1="Unlimited Cases"
                  point2="500 Chatbot Queries"
                  point3="Basic Reporting"
                  point4="Case Tracking"
                  point5="Case Specific Chatbot"
                  point6="Multi-Language Support"
                  plan="pro_yearly"
                />
                <Card
                  handlePayment={handlePayment}
                  borderColor="border-[#F58A0E]"
                  hoverColor="hover:bg-[#F58A0E]"
                  subName="Enterprise"
                  time="year"
                  middle="hidden"
                  pricing={42000}
                  nonDiscounted={59988}
                  point1="Unlimited Everything"
                  point2="Unlimited Chatbot Queries"
                  point3="Advance Reporting"
                  point4="Case Analytics"
                  point5="Collaboration Tools"
                  point6="Multi-Language Support"
                  plan="enterprises_yearly"
                />
              </div>
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </div>
  );
};

const Card = ({
  handlePayment,
  borderColor,
  hoverColor,
  subName,
  pricing,
  nonDiscounted,
  time,
  middle,
  point1,
  point2,
  point3,
  point4,
  point5,
  point6,
  plan,
}) => {
  return (
    <div
      className={`${borderColor} relative border-2 hover:border-t-[20px] border-t-8 rounded-md flex flex-col gap-6 px-5 py-6 max-w-[350px] hover:-translate-y-4 hover:shadow-lg hover:shadow-black transition-all duration-300`}
    >
      {/* Absolute */}
      <div
        className={`${middle} absolute left-[50%] top-[-37px] translate-x-[-50%] rounded-t-md px-2 py-1.5 flex items-center gap-2 bg-[#F4E2FF] border border-[#4F077E]`}
      >
        <PiStarFourFill className="text-xs text-[#700BB2]" />
        <p className="font-mulish font-bold text-xs text-[#700BB2]">
          Most Popular
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h4 className="font-mulish font-bold text-2xl">{subName}</h4>
        <p className="font-mulish font-semibold mb-2">
          Get started for free by using our 7 day free trial
        </p>
        <p className="font-mulish font-semibold text-xl">
          ₹ {pricing}/ {time}{" "}
          {nonDiscounted ? (
            <span className="text-sm stroke-black ml-2 line-through">
              ₹{nonDiscounted}/ {time}
            </span>
          ) : (
            ""
          )}
        </p>
      </div>
      <div
        style={{ "--hover-color": borderColor }}
        className="border border-[#202020] hover:border-[--hover-color] rounded-md w-full"
      >
        <button
          style={{ "--hover-color": borderColor }}
          className={`flex items-center w-full justify-between bg-[#f5f5f5] dark:bg-gray-700 ${hoverColor} hover:text-white transition-all duration-200`}
          // onClick={() => handlePayment(pricing, plan)}
        >
          <p className="px-5 font-mulish font-bold text-lg">
            Get Started for Free
          </p>
          <div className="bg-[#202020] text-white px-4 py-4 rounded-r-sm">
            <FaAngleRight />
          </div>
        </button>
      </div>
      <hr className="border-black border" />
      <div className="flex flex-col gap-3">
        <div className="flex items-center gap-4">
          <PiSealCheckFill className="text-xl" />
          <p className="text-xl font-mulish font-semibold">{point1}</p>
        </div>
        <div className="flex items-center gap-4">
          <PiSealCheckFill className="text-xl" />
          <p className="text-xl font-mulish font-semibold">{point2}</p>
        </div>
        <div className="flex items-center gap-4">
          <PiSealCheckFill className="text-xl" />
          <p className="text-xl font-mulish font-semibold">{point3}</p>
        </div>
        <div className="flex items-center gap-4">
          <PiSealCheckFill className="text-xl" />
          <p className="text-xl font-mulish font-semibold">{point4}</p>
        </div>
        <div className="flex items-center gap-4">
          <PiSealCheckFill className="text-xl" />
          <p className="text-xl font-mulish font-semibold">{point5}</p>
        </div>
        <div className="flex items-center gap-4">
          <PiSealCheckFill className="text-xl" />
          <p className="text-xl font-mulish font-semibold">{point6}</p>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
