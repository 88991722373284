import React, { useEffect, useMemo, useState } from "react";
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from "../../../components/ui/select";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import axiosInstance from "../../../utils/axiosInstance";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const CauseList = () => {
  const [dateClicked, setDateClicked] = useState(false);
  const [allCases, setAllCases] = useState({
    supremeCourtCases: [],
    highCourtCases: [],
    allHighCourtCases: [],
    districtCases: [],
    punjabHighCourtCases: [],
  });
  const [filteredCases, setFilteredCases] = useState([]);
  const [events, setEvents] = useState();

  const access_token = sessionStorage.getItem("access_token"); // Authorization Token

  // Fetching All Cases
  const fetchAllCases = useMemo(
    () => async () => {
      try {
        const response = await axiosInstance.get("dashboard/casedetails/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });
        // console.log(response.data);
        setAllCases({
          supremeCourtCases: response.data?.supreme_court_cases.reverse() || [],
          highCourtCases: response.data?.high_court_cases.reverse() || [],
          allHighCourtCases:
            response.data?.all_high_court_cases.reverse() || [],
          districtCases: response.data?.district_cases.reverse() || [],
          punjabHighCourtCases:
            response.data?.punjab_highcourt_cases.reverse() || [],
        });
      } catch (err) {
        console.log(err);
      }
    },
    [access_token]
  );

  const getfilteredCases = () => {
    const filtered = [].concat(...Object.values(allCases));
    setFilteredCases(filtered);
    console.log(filtered);
  };

  const getEvents = () => {
    const hearingDatesCases = filteredCases.filter(
      (filteredCase) => filteredCase?.next_hearing_date
    );
    setEvents(
      hearingDatesCases.map((hearingDateCase) => ({
        ...hearingDateCase,
        title: hearingDateCase?.parties,
        date: moment(hearingDateCase?.next_hearing_date).format("YYYY-MM-DD"),
      }))
    );
    // console.log(hearingDatesCases);
    // console.log(events);
  };

  useEffect(() => {
    fetchAllCases();
  }, [fetchAllCases]);

  useEffect(() => {
    getfilteredCases();
  }, [allCases]);

  useEffect(() => {
    getEvents();
  }, [filteredCases]);

  const handleDateClick = (arg) => {
    console.log(arg);
  };

  const navigate = useNavigate();

  const handleEventClick = (eventInfo) => {
    console.log(eventInfo.event.title);
    let eachcase = eventInfo.event.extendedProps;
    let court = eachcase.court;
    let caseid;
    if (court === "delhi") {
      caseid = eachcase.highcourtcase;
      navigate(`/all-cases-dashboard/high-court-delhi/${caseid}`, {
        state: { all_cases_dashboard: true },
      });
    } else if (court === "punjab") {
      caseid = eachcase.punjabhighcourtcase;
      navigate(`/all-cases-dashboard/high-court-punjab-haryana/${caseid}`, {
        state: { all_cases_dashboard: true },
      });
    } else if (court === "allhighcourt") {
      caseid = eachcase.allhighcourtcase;
      navigate(`/all-cases-dashboard/all-high-court-cases/${caseid}`, {
        state: { all_cases_dashboard: true },
      });
    } else if (court === "supreme") {
      caseid = eachcase.supremecourtcase;
      navigate(`/all-cases-dashboard/supreme-court/${caseid}`, {
        state: { all_cases_dashboard: true },
      });
    } else if (court === "district") {
      caseid = eachcase.cnr_number;
      navigate(`/all-cases-dashboard/district-court/${caseid}`, {
        state: { all_cases_dashboard: true },
      });
    }
  };

  function renderEventContent(eventInfo) {
    return (
      <>
        <b>{eventInfo.timeText}</b>
        <div className="truncate">
          <i className="cursor-pointer truncate">{eventInfo.event.title}</i>
        </div>
      </>
    );
  }

  return (
    <div className="px-6 flex flex-col gap-6">
      <div className="w-[250px]">
        <p className="font-mulish mt-2 font-semibold italic">
          Click on a case to get its details
        </p>
        {/* <Select
          required
          name="case_type"
          autoComplete="off"
          defaultValue="Monthly Cause List"
        >
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="Monthly Cause List">
              Monthly Cause List
            </SelectItem>
            <SelectItem value="Weekly Cause List">Weekly Cause List</SelectItem>
          </SelectContent>
        </Select> */}
      </div>
      <div className="pb-10">
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          dateClick={handleDateClick}
          eventContent={renderEventContent}
          eventClick={handleEventClick}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "",
          }}
          events={events}
        />
      </div>
      {/* <div>{dateClicked ? "Yes" : "No"}</div> */}
    </div>
  );
};

export default CauseList;
