// React utils
import React, { useEffect, useState, useCallback } from "react";

// React Router
import { useParams } from "react-router-dom";

// Axios
import axiosInstance from "../../utils/axiosInstance";

// Loader Component
import Loader from "../HighCourtDelhi/Loader";

// Icons
import { MdOutlineFileDownload } from "react-icons/md";
import moment from "moment/moment";

const CaseSearchDetails = () => {
  const [searchDetails, setSearchDetails] = useState(); // Object
  const [detailsLoading, setDetailsLoading] = useState(false); // Boolean

  const { id } = useParams();

  const access_token = sessionStorage.getItem("access_token"); // Authorization Token

  const fetchSearchCaseDetails = useCallback(async () => {
    try {
      setDetailsLoading(true);
      const response = await axiosInstance.get(`case/detail?case_id=${id}`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      console.log(response.data);
      setSearchDetails(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setDetailsLoading(false);
    }
  }, [id]);

  const formatText = (text) => {
    if (!text) return null;

    const lines = text.split("\n");

    return lines
      .map((line, index) => {
        if (!line.trim()) return null;

        // Petitioner Arguments
        if (line.startsWith("Petitioner Arguments:")) {
          const bold = line.substring(0, 21);
          const content = line.substring(21);
          return (
            <div key={index} className="px-3 py-4 flex flex-col gap-1">
              <strong>{bold}</strong>
              <p>{content}</p>
            </div>
          );
        }

        // Respondent Arguments
        if (line.startsWith("Respondent Arguments:")) {
          const bold = line.substring(0, 21);
          const content = line.substring(21);
          return (
            <div key={index} className="px-3 py-4 flex flex-col gap-1">
              <strong>{bold}</strong>
              <p>{content}</p>
            </div>
          );
        }

        // Rationale
        if (line.startsWith("Rationale:")) {
          const bold = line.substring(0, 10);
          const content = line.substring(10);
          return (
            <div key={index} className="px-3 py-4 flex flex-col gap-1">
              <strong>{bold}</strong>
              <p>{content}</p>
            </div>
          );
        }

        // Outcome
        if (line.startsWith("Outcome:")) {
          const bold = line.substring(0, 8);
          const content = line.substring(8);
          return (
            <div key={index} className="px-3 py-4 flex flex-col gap-1">
              <strong>{bold}</strong>
              <p>{content}</p>
            </div>
          );
        }

        // Conclusion
        if (line.startsWith("Conclusion:")) {
          const bold = line.substring(0, 11);
          const content = line.substring(11);
          return (
            <div key={index} className="px-3 py-4 flex flex-col gap-1">
              <strong>{bold}</strong>
              <p>{content}</p>
            </div>
          );
        }

        // Handle headings
        const headingMatch = line.match(/^(#+)\s+(.*)/);
        if (headingMatch) {
          //   const level = headingMatch[1].length;
          const content = headingMatch[2].replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <strong className="text-2xl" key={index}>
              {content}
            </strong>
          );
        }

        const numberedMatch = line.match(/^(\d+\.\s+)\*\*(.*?)\*\*$/);
        if (numberedMatch) {
          const numberPart = numberedMatch[1]; // e.g., "1. "
          const content = numberedMatch[2].trim(); // Get the text after ** and remove it
          return (
            <div key={index} className="">
              {numberPart}
              <strong>{content}</strong>
            </div>
          );
        }

        if (line.startsWith("-**")) {
          // Handle list items with bold text
          const content = line.substring(3, line.length - 2).trim();
          return (
            <ul key={index} className="">
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("-**")) {
          const content = line.substring(8).trim();
          return (
            <ul key={index} className="">
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("   - **")) {
          const content = line.substring(7).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} className="">
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("  - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }} className="">
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }
        if (line.startsWith("   - ")) {
          const content = line.substring(4).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }} className="">
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }

        if (line.startsWith("     - ")) {
          const content = line.substring(6).trim().replace(/\*\*/g, ""); // Remove leading spaces and **
          return (
            <ul key={index} style={{ marginLeft: "10px" }} className="">
              <li style={{ marginLeft: "10px" }}>{content}</li>
            </ul>
          );
        }

        // Handle regular list items
        if (line.startsWith("-")) {
          const cleanLine = line.substring(1).trim();
          const contentWithoutBold = cleanLine.replace(/\*\*(.*?)\*\*/g, "$1");
          return (
            <p key={index} style={{ marginLeft: "10px" }} className="px-3">
              <li>{contentWithoutBold}</li>
            </p>
          );
        }

        if (line.startsWith("**") && line.endsWith("**")) {
          const cleanLine = line.substring(2, line.length - 2).trim(); // Remove the first and last two asterisks
          return (
            <strong key={index} className="px-3">
              <p>{cleanLine}</p>
            </strong>
          );
        }

        // Handle bold text within a paragraph
        const parts = line
          .replace(/\*\*$/, "")
          .replace(/\*\*(.*?)\*\*/g, "<strong>$1</strong>")
          .split(/(<strong>.*?<\/strong>)/)
          .map((part, idx) => {
            if (part.startsWith("<strong>") && part.endsWith("</strong>")) {
              const cleanText = part.substring(8, part.length - 9);
              return <strong key={idx}>{cleanText}</strong>;
            } else {
              return (
                <span key={idx} className="">
                  {part}
                </span>
              );
            }
          });

        return (
          <p key={index} style={{ textAlign: "justify" }} className="px-3">
            {parts}
          </p>
        );
      })
      .filter((component) => component !== null);
  };

  useEffect(() => {
    fetchSearchCaseDetails();
  }, [fetchSearchCaseDetails]);

  return (
    <div className="pt-32 pb-20 px-12 bg-white text-black dark:bg-dark-600 dark:text-gray-200 flex flex-col gap-10 font-raleway">
      {detailsLoading ? (
        <div className="flex h-[calc(100vh-208px)] w-full justify-center items-center">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-14 py-6 border border-[#D9D9D9] dark:border-gray-600 rounded-lg">
          <div className="flex flex-col gap-4 px-6">
            <div className="flex justify-between items-center gap-4">
              <h1 className="text-xl font-semibold">
                {searchDetails?.petitioner?.toUpperCase()} VS{" "}
                {searchDetails?.respondent?.toUpperCase()}
              </h1>
              {searchDetails?.pdf_url || searchDetails?.url ? (
                <div className="px-3 font-raleway py-2 text-sm flex gap-1 items-center bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700 rounded-sm">
                  <MdOutlineFileDownload className="text-lg" />
                  <a
                    href={
                      searchDetails?.pdf_url
                        ? searchDetails?.pdf_url
                        : searchDetails?.url
                        ? searchDetails?.url
                        : ""
                    }
                    target="_blank"
                    rel="noreferrer"
                  >
                    Open PDF
                  </a>
                </div>
              ) : (
                <div className="px-3 font-raleway font-medium py-2 text-sm flex gap-1 items-center bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700 rounded-sm">
                  No PDF Found
                </div>
              )}
            </div>
            <div className="flex gap-4">
              <div className="flex basis-[400px] flex-col gap-1">
                <p>Case Number</p>
                <p className="font-semibold">
                  {searchDetails?.case_type
                    ? searchDetails?.case_type
                    : searchDetails?.case_no === "0"
                    ? "Not Available"
                    : searchDetails?.case_no}
                </p>
              </div>
              <div className="flex basis-[200px] flex-col gap-1">
                <p>Date</p>
                <p className="font-semibold">
                  {searchDetails?.date_of_judgment
                    ? moment(searchDetails?.date_of_judgment).format(
                        "DD-MMM-YY"
                      )
                    : moment(searchDetails?.date).format("DD-MMM-YY")}
                </p>
              </div>
              <div className="flex basis-[200px] flex-col gap-1">
                <p>Court</p>
                <p className="font-semibold">{searchDetails?.court}</p>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex basis-[400px] flex-col gap-1">
                <p>Diary Number</p>
                <p className="font-semibold">{searchDetails?.diary_no}</p>
              </div>
              <div className="flex basis-[200px] flex-col gap-1">
                <p>Bench</p>
                <p className="font-semibold">
                  {searchDetails?.judgment_delivered_by
                    ? searchDetails?.judgment_delivered_by
                    : searchDetails?.bench}
                </p>
              </div>
              <div className="flex basis-[250px] flex-col gap-1">
                <p>Petitioner’s Advocate </p>
                <p className="font-semibold">{searchDetails?.pet_adv}</p>
              </div>
              <div className="flex basis-[250px] flex-col gap-1">
                <p>Respondent's Advocate </p>
                <p className="font-semibold">{searchDetails?.res_adv}</p>
              </div>
            </div>
          </div>
          {/* Bottom Section */}
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-2">
              <div className="p-3 bg-[#fafafa] dark:bg-dark-450 border-y border-[#d9d9d9] dark:border-gray-600">
                <h4 className="text-xl font-semibold">Summary</h4>
              </div>
              <div>
                <p className="px-3 py-4">
                  {searchDetails?.summary ? (
                    formatText(searchDetails?.summary)
                  ) : (
                    <span className="px-3">Summary Not Available</span>
                  )}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="p-3 bg-[#fafafa] dark:bg-dark-450 border-y border-[#d9d9d9] dark:border-gray-600">
                <h4 className="text-xl font-semibold">Facts</h4>
              </div>
              <div>
                <p className="px-3 py-4">{formatText(searchDetails?.facts)}</p>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="p-3 bg-[#fafafa] dark:bg-dark-450 border-y border-[#d9d9d9] dark:border-gray-600">
                <h4 className="text-xl font-semibold">Arguments</h4>
              </div>
              <div className="px-3">{formatText(searchDetails?.arguments)}</div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="p-3 bg-[#fafafa] dark:bg-dark-450 border-y border-[#d9d9d9] dark:border-gray-600">
                <h4 className="text-xl font-semibold">Precedents</h4>
              </div>
              <div>
                <p className="px-3 py-4">
                  {formatText(searchDetails?.precedents)}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <div className="p-3 bg-[#fafafa] dark:bg-dark-450 border-y border-[#d9d9d9] dark:border-gray-600">
                <h4 className="text-xl font-semibold">Decisions</h4>
              </div>
              <div>
                <div className="px-3">
                  {formatText(searchDetails?.decision)}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              {searchDetails?.acts.length > 0 && (
                <>
                  <div className="p-3 bg-[#fafafa] dark:bg-dark-450 border-y border-[#d9d9d9] dark:border-gray-600">
                    <h4 className="text-xl font-semibold">
                      Acts & Section Cited
                    </h4>
                  </div>
                  <ul className="flex flex-col">
                    {searchDetails?.acts.map((act, idx) => (
                      <li key={idx} className="px-6 py-2">
                        {act}
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CaseSearchDetails;
