// React Utils
import React, { useEffect, useState } from "react";

// React Router
import { useSearchParams, useNavigate, Link } from "react-router-dom";

// Axios
import axiosInstance from "../../utils/axiosInstance";

// Icons
import { RiSearchLine } from "react-icons/ri";
import { IoMicOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";
import { FaAngleRight } from "react-icons/fa6";
import SearchIcon from "../../assets/images/LegalCaseSearch/Search.png";

// ShadCN Pagination
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
} from "../../components/ui/pagination";

// React Toast
import { toast } from "react-toastify";

// Moment Date
import moment from "moment/moment";

// Loader Component
import Loader from "../HighCourtDelhi/Loader";

const SearchResults = () => {
  const [searchParams, setSearchParams] = useSearchParams(); // Object
  const searchParamsInput = searchParams.get("q");
  const [searchInput, setSearchInput] = useState(searchParams.get("q") || ""); // String
  const [isListening, setIsListening] = useState(false); // Boolean
  const [searchResults, setSearchResults] = useState(); // Array
  const [loadingResults, setLoadingResults] = useState(false);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const access_token = sessionStorage.getItem("access_token"); // Authorization Token

  const navigate = useNavigate();

  const handleSearchCaseClick = (id) => {
    navigate(`/judgement-search/${id}`);
  };

  const handleSearchResults = async (page) => {
    setLoadingResults(true);
    console.log(page);
    try {
      const response = await axiosInstance.get(
        page === 1
          ? `case/search?item=${searchInput}`
          : `case/search?item=${searchInput}&page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setSearchResults(response.data.results);
      setCount(response.data.count);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingResults(false);
    }
  };

  useEffect(() => {
    handleSearchResults(currentPage);
  }, [searchParamsInput, currentPage]);

  const totalPages = Math.ceil(count / ITEMS_PER_PAGE);

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    setSearchParams({ q: searchInput });
    handleSearchResults();
    setCurrentPage(1);
  };

  // Handle Speech Recognition
  const handleSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      toast.error(
        "Speech recognition is not supported in this browser. Try using Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onstart = () => {
      setIsListening(true);
    };

    recognition.onend = () => {
      setIsListening(false);
    };

    recognition.onresult = (event) => {
      const speechResult = event.results[0][0].transcript;
      setSearchInput(speechResult);
    };

    recognition.onerror = (event) => {
      toast.error("Speech recognition error: " + event.error);
    };

    recognition.start();
  };

  return (
    <div className="pt-28 pb-40 px-12 flex flex-col gap-8 font-raleway bg-white text-black dark:bg-dark-600 dark:text-gray-200">
      <div className="px-6">
        <Link to=".." relative="path">
          &larr; <span>Back to Judgement Search</span>
        </Link>
      </div>
      <form
        className="flex gap-3 w-full items-center"
        onSubmit={handleSearchSubmit}
      >
        <div className="w-[90%] relative">
          <input
            type="text"
            className="px-4 py-4 w-full font-raleway font-semibold border border-[#d9d9d9] dark:placeholder:text-gray-50/50 dark:bg-dark-450 dark:text-white dark:border-gray-600 rounded-lg"
            placeholder={
              isListening
                ? "Listening..."
                : "Search for “Case Number, Petitioner Name, Respondent Name”"
            }
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            required
            autoComplete="off"
          />
          {searchInput && (
            <IoCloseOutline
              onClick={() => setSearchInput("")}
              className="absolute right-20 text-2xl top-[50%] translate-y-[-50%] cursor-pointer"
            />
          )}
          <div className="absolute flex gap-4 text-2xl border-l border-l-[#D9D9D9] dark:border-l-gray-600  top-[50%] translate-y-[-50%] py-4 px-4 right-1 bg-whit dark:bg-dark-450">
            <IoMicOutline
              onClick={handleSpeechRecognition}
              className="cursor-pointer select-none"
            />
          </div>
        </div>
        {/* Search Button */}
        <div className="w-[10%]">
          <button className="flex font-raleway font-medium w-full px-4 py-[15px] bg-black dark:bg-light-600 dark:text-gray-700 items-center justify-center gap-2 text-white text-lg">
            <RiSearchLine className="font-black" />
            Search
          </button>
        </div>
      </form>
      {searchResults?.length > 0 ? (
        !loadingResults ? (
          <div className="w-full flex flex-col gap-8">
            <p className="font-raleway font-semibold text-lg text-[#717171]">
              Search Results...({count})
            </p>
            <div className="flex flex-col gap-6">
              {searchResults.map((eachResult) => (
                <ResultCard
                  eachResult={eachResult}
                  handleSearchCaseClick={handleSearchCaseClick}
                  key={eachResult.id}
                />
              ))}
            </div>
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious
                    onClick={() =>
                      currentPage > 1 && setCurrentPage(currentPage - 1)
                    }
                    className={
                      currentPage > 1 ? `cursor-pointer` : `cursor-not-allowed`
                    }
                  />
                </PaginationItem>
                <PaginationItem>
                  <PaginationNext
                    onClick={() =>
                      currentPage < totalPages &&
                      setCurrentPage(currentPage + 1)
                    }
                    className={
                      currentPage < totalPages
                        ? `cursor-pointer`
                        : `cursor-not-allowed`
                    }
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        ) : (
          <div className="flex justify-center items-center h-[50vh]">
            <Loader />
          </div>
        )
      ) : loadingResults ? (
        <div className="flex justify-center items-center h-[50vh]">
          <Loader />
        </div>
      ) : (
        <EmptySearchResult />
      )}
    </div>
  );
};
const EmptySearchResult = () => {
  return (
    <div className="flex flex-col items-center gap-2 text-center mt-4">
      <img src={SearchIcon} alt="No Item Found" />
      <p className="font-mulish font-bold text-2xl">No Result Found</p>
      <p className="font-raleway font-medium text-lg w-2/3 mt-2">
        “Please check for any spelling errors or try alternative keywords.”
      </p>
    </div>
  );
};

const ResultCard = ({ eachResult, handleSearchCaseClick }) => {
  return (
    <div
      onClick={() => handleSearchCaseClick(eachResult.id)}
      className=" border border-[#d9d9d9] dark:border-gray-600 rounded-xl cursor-pointer hover:bg-[#F3F3F3] dark:hover:bg-sh-dark-500/30 transition-colors"
    >
      <div className="flex flex-col rounded-xl gap-4 border-l-[16px] border-l-[#f3f3f3] dark:border-l-sh-dark-500/30 px-6 py-6 ">
        <div className="flex justify-between">
          <h2 className="font-raleway font-semibold text-xl">{`${eachResult?.petitioner?.toUpperCase()} VS ${eachResult?.respondent?.toUpperCase()}`}</h2>
          <div className="w-6 h-6 rounded-full flex justify-center items-center border border-[#d9d9d9] dark:border-gray-600">
            <FaAngleRight className="text-sm" />
          </div>
        </div>
        <hr />
        <div className="flex gap-4">
          <div className="flex flex-col gap-3 basis-[400px]">
            <p className="font-raleway">Case Number</p>
            <p className="font-raleway font-semibold">
              {eachResult?.case_type
                ? eachResult?.case_type
                : eachResult?.case_no === "0"
                ? "Not Available"
                : eachResult?.case_no}
            </p>
          </div>
          <div className="flex flex-col gap-3 basis-[300px]">
            <p className="font-raleway">Date</p>
            <p className="font-raleway font-semibold">
              {eachResult?.date_of_judgment
                ? moment(eachResult?.date_of_judgment).format("DD-MMM-YY")
                : moment(eachResult?.date).format("DD-MMM-YY")}
            </p>
          </div>
          <div className="flex flex-col gap-3 basis-[300px]">
            <p className="font-raleway">Court</p>
            <p className="font-raleway font-semibold">{eachResult?.court}</p>
          </div>
          <div className="flex flex-col gap-3 basis-[300px]">
            <p className="font-raleway">Bench</p>
            <p className="font-raleway font-semibold">
              {eachResult?.judgment_delivered_by
                ? eachResult?.judgment_delivered_by
                : eachResult?.bench}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResults;
