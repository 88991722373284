// src/ScrollStack.js
import React from "react";
import "../assets/css/CardStack.css";

const CardStack = ({ cards }) => {
  return (
    <div className="stack-card-main-container dark:bg-dark-600 bg-light-600">
      <header className="fixed-container">
        <h2 className="centered-text dark:text-gray-300 text-gray-700 max-sm:text-3xl">
          <span className="dark:text-gray-500 text-gray-600">Why</span> Choose
          Us
        </h2>
      </header>
      <main className="feature-section">
        <div className="stack-comp">
          <ul id="cards">
            {cards.map((card, index) => (
              <li
                key={index}
                id={`card-${index + 1}`}
                className={`stack-card ${card.backgroundColor}`}
                // style={{ backgroundColor: card.backgroundColor }}
              >
                <div
                  className={`card-content ${card.backgroundColor} max-sm:px-4`}
                  //   style={{ backgroundColor: card.backgroundColor }}
                >
                  <div className="card-right-side gap-4">
                    <h1
                      className={`${card.titleColor} leading-none text-[45px] max-md:text-2xl max-sm:text-lg heading-text`}
                      //   style={{ color: card.titleColor }}
                    >
                      {card.title}
                    </h1>
                    <p
                      className={`${card.contentColor} tracking-wide text-xl max-sm:text-sm`}
                      //   style={{ color: card.contentColor }}
                    >
                      {card.content}
                    </p>
                  </div>
                  <div className="card-left-side">
                    <video
                      src={card.video}
                      alt={card.title}
                      className="card-video"
                      autoPlay
                      loop
                      muted
                      playsInline // Ensures the video plays inline on mobile browsers
                      preload="auto" // Ensures the video is preloaded
                    />
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </main>
    </div>
  );
};

export default CardStack;
