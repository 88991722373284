// React Utils
import React from "react";

// Outlext Context
import { useOutletContext } from "react-router-dom";

// Accordion Shadcn
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

// Tabs Shadcn
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";

const SimilarCases = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  return (
    <div className="flex flex-col gap-10 px-4">
      <Tabs defaultValue="Similar Cases">
        <TabsList>
          <TabsTrigger value="Similar Cases">Similar Cases</TabsTrigger>
          <TabsTrigger value="Precedent Cases">Precedent Cases</TabsTrigger>
        </TabsList>
        <TabsContent value="Similar Cases">
          <div className="flex flex-col gap-10 mt-10">
            <div>
              <p className="text-xl font-semibold font-raleway border-b-2 pb-1 w-fit">
                Similar Cases Found
              </p>
            </div>
            <div className="flex flex-col gap-10">
              {caseDetails?.analytics?.["Similar Cases Found"]?.length > 0
                ? caseDetails?.analytics?.["Similar Cases Found"]?.map(
                    (cases, index) => (
                      <div
                        className="px-10 max-md:px-0 flex gap-6 w-full"
                        key={index}
                      >
                        <p className="text-2xl font-medium mt-3 max-md:hidden">
                          {index + 1}.
                        </p>
                        <Accordion
                          type="single"
                          collapsible
                          className="border border-[#D9D9D9] dark:border-gray-600 px-5 rounded-lg w-full"
                        >
                          <AccordionItem value="item-1" className="border-none">
                            <AccordionTrigger className="text-gray-700 dark:text-gray-200 hover:no-underline">
                              <div className="flex flex-col gap-4">
                                <div className="flex gap-10 max-md:gap-2">
                                  <p className="text-left min-w-[150px] max-md:text-xs max-md:w-[100px] font-normal">
                                    Case Number
                                  </p>
                                  <p className="max-md:text-xs">
                                    {cases?.["Case Number"]}
                                  </p>
                                </div>
                                <div className="flex gap-10 max-md:gap-2">
                                  <p className="text-left min-w-[150px] max-md:text-xs max-md:w-[100px] font-normal">
                                    Section & Acts
                                  </p>
                                  <div className="flex flex-wrap gap-x-2 gap-y-2 max-md:text-xs text-left">
                                    {cases?.["Sections"]?.map(
                                      (section, index) => (
                                        <p key={index}>{section} |</p>
                                      )
                                    )}
                                  </div>
                                </div>
                                <div className="flex gap-10 max-md:gap-2">
                                  <p className="text-left min-w-[150px] max-md:text-xs max-md:w-[100px] font-normal">
                                    Decision
                                  </p>
                                  <p className="max-md:text-xs">
                                    {cases?.["Decision"][0].toUpperCase() +
                                      cases?.["Decision"].slice(1)}
                                  </p>
                                </div>
                                <div className="flex gap-10 max-md:gap-2">
                                  <p className="text-left min-w-[150px] max-md:text-xs max-md:w-[100px] font-normal">
                                    Judge(s) Name
                                  </p>
                                  <div className="flex flex-wrap gap-x-2 gap-y-2 max-md:text-xs text-left">
                                    {cases?.["Judges"]
                                      ? cases?.["Judges"]?.map(
                                          (judge, index) => (
                                            <p key={index}>{judge} |</p>
                                          )
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </AccordionTrigger>
                            <AccordionContent>
                              <hr />
                              <div className="py-4 flex flex-col gap-4">
                                <p className="font-raleway w-fit text-lg font-medium text-blue- border-b-2 max-md:text-sm">
                                  Summary
                                </p>
                                <p className="text-base max-md:text-sm leading-[1.9] max-md:leading-6 tracking-[0.3px]">
                                  {cases?.["Explanation"]}
                                </p>
                              </div>
                            </AccordionContent>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    )
                  )
                : "No Similar Cases Found."}
            </div>
            <hr />
          </div>
        </TabsContent>
        <TabsContent value="Precedent Cases">
          <div className="flex flex-col gap-10 mt-10">
            <div>
              <p className="text-xl font-semibold font-raleway border-b-2 pb-1 w-fit">
                Precedent Cases
              </p>
            </div>
            <div className="flex flex-col gap-10">
              {caseDetails?.analytics?.["Precedent Cases"]?.length > 0
                ? caseDetails?.analytics?.["Precedent Cases"]?.map(
                    (cases, index) => (
                      <div
                        className="px-10 max-md:px-0 flex gap-6 w-full"
                        key={index}
                      >
                        <p className="text-2xl font-medium mt-3 max-md:hidden">
                          {index + 1}.
                        </p>
                        <div className="border border-[#D9D9D9] dark:border-gray-600 px-5 py-5 rounded-lg w-full">
                          <div className="flex flex-col gap-4">
                            <div className="flex gap-10 max-md:gap-2">
                              <p className="text-left min-w-[170px] max-md:text-xs max-md:min-w-[120px] font-normal">
                                Case Name
                              </p>
                              <p className="font-medium max-md:text-xs">
                                {cases?.["Case Name"]}
                              </p>
                            </div>
                            <div className="flex gap-10 max-md:gap-2">
                              <p className="text-left min-w-[170px] max-md:text-xs max-md:min-w-[120px] font-normal">
                                Citation
                              </p>
                              <p className="font-medium max-md:text-xs">
                                {cases?.["Citation"]}
                              </p>
                            </div>
                            <div className="flex gap-10 max-md:gap-2">
                              <p className="text-left min-w-[170px] max-md:text-xs max-md:min-w-[120px] font-normal">
                                Context of Application
                              </p>
                              <p className="font-medium max-md:text-xs">
                                {cases?.["Context of Application"]}
                              </p>
                            </div>
                            <div className="flex gap-10 max-md:gap-2">
                              <p className="text-left min-w-[170px] max-md:text-xs max-md:min-w-[120px] font-normal">
                                Key Principles
                              </p>
                              <p className="font-medium max-md:text-xs">
                                {cases?.["Key Principles"]}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  )
                : "No precedents were cited in this case."}
            </div>
            <hr />
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default SimilarCases;
