import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarRail,
} from "../../components/ui/sidebar";

import { RxDashboard } from "react-icons/rx";
import { MdOutlineMenuBook } from "react-icons/md";
import { LuUsers } from "react-icons/lu";
import { LuNewspaper } from "react-icons/lu";
import { PiBellRingingLight } from "react-icons/pi";
import { PiShareFat } from "react-icons/pi";

export function AppSidebar({ setGetDataFromChild }) {
  const { pathname } = useLocation();
  let path = pathname.substring(20);
  if (path === "" || path === "/") {
    path = "Dashboard";
  } else if (path === "/cause-list") {
    path = "Cause List";
  } else if (path === "/my-clients") {
    path = "My Clients";
  } else if (path === "/acts") {
    path = "Acts";
  } else if (path === "/share") {
    path = "Share";
  }

  const [activeLink, setActiveLink] = useState(path);

  useEffect(() => {
    setGetDataFromChild(path);
  }, []);

  return (
    <Sidebar className="mt-20" collapsible="icon">
      <SidebarContent>
        <SidebarGroup>
          <SidebarGroupLabel className="truncate">
            All Cases Dashboard
          </SidebarGroupLabel>
          <SidebarGroupContent>
            <SidebarMenu>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeLink === "Dashboard"}
                  onClick={() => {
                    setActiveLink("Dashboard");
                    setGetDataFromChild("Dashboard");
                  }}
                >
                  <NavLink to="." end>
                    <RxDashboard />
                    <p>Dashboard</p>
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeLink === "Cause List"}
                  onClick={() => {
                    setActiveLink("Cause List");
                    setGetDataFromChild("Cause List");
                  }}
                >
                  <NavLink to="cause-list">
                    <MdOutlineMenuBook />
                    <p className="truncate">Cause List</p>
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeLink === "My Clients"}
                  onClick={() => {
                    setActiveLink("My Clients");
                    setGetDataFromChild("My Clients");
                  }}
                >
                  <NavLink to="my-clients">
                    <LuUsers />
                    <p className="truncate">My Clients</p>
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeLink === "Acts"}
                  onClick={() => {
                    setActiveLink("Acts");
                    setGetDataFromChild("Acts");
                  }}
                >
                  <NavLink to="acts">
                    <LuNewspaper />
                    Acts
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
              {/* <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeLink === "Alerts"}
                  onClick={() => {
                    setActiveLink("Alerts");
                    setGetDataFromChild("Alerts");
                  }}
                >
                  <NavLink to="alerts">
                    <PiBellRingingLight />
                    Alerts
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem> */}
              <SidebarMenuItem>
                <SidebarMenuButton
                  asChild
                  isActive={activeLink === "Share"}
                  onClick={() => {
                    setActiveLink("Share");
                    setGetDataFromChild("Share");
                  }}
                >
                  <NavLink to="share">
                    <PiShareFat />
                    Share
                  </NavLink>
                </SidebarMenuButton>
              </SidebarMenuItem>
            </SidebarMenu>
          </SidebarGroupContent>
        </SidebarGroup>
      </SidebarContent>
      <SidebarRail />
    </Sidebar>
  );
}
