import React from "react";

import { useOutletContext } from "react-router-dom";

import { FaFilePdf } from "react-icons/fa6";

// ShadCN Sheet
import { Sheet, SheetContent, SheetTrigger } from "../../components/ui/sheet";

const OrderSheet = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  return (
    <div className="flex flex-col gap-4 px-6 pb-6 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
      <div className="flex flex-col gap-2 border border-[#d9d9d9] dark:border-gray-600 px-3 py-4">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-4">
            <FaFilePdf className="text-2xl text-red-600 dark:text-red-400" />
            <p className="font-light max-md:text-sm">Complete Order Sheet</p>
          </div>
          <Sheet>
            <SheetTrigger className="font-raleway px-2 border-b-2 dark:border-gray-600 font-bold">
              Read Now
            </SheetTrigger>
            <SheetContent>
              <object
                data={
                  caseDetails?.merged_pdf
                    ? caseDetails?.merged_pdf
                    : caseDetails?.pdf_path
                }
                type="application/pdf"
                width="100%"
                height="100%"
              >
                <p className="leading-1.5 tracking-wide mt-4">
                  <a
                    href={
                      caseDetails?.merged_pdf
                        ? caseDetails?.merged_pdf
                        : caseDetails?.pdf_path
                    }
                  >
                    <u>
                      <i>Click here</i>
                    </u>{" "}
                  </a>
                  to View/Download the Complete Order Sheet.
                </p>
              </object>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </div>
  );
};

export default OrderSheet;
