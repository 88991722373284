// React Util
import React, { useState, useEffect, useMemo } from "react";

// Axios Util
import axiosInstance from "../../utils/axiosInstance";

// Date Formatting
import moment from "moment";

// Toast Notifications
import { toast } from "react-toastify";

// React Router Navigate
import { useNavigate } from "react-router-dom";

// Importing Case Types & Year
import { years, caseTypes } from "./data";

import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";

// ShadCN Input and Select
import { Input } from "../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

// Get Width
import { useWindowWidth } from "@react-hook/window-size";

// Dialog Box
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../components/ui/dialog";

// Icons
import { PiBookOpenText } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";

// Main Component
const SupremeCourt = () => {
  const [casesStats, setCasesStats] = useState({}); // Object
  const [allCases, setAllCases] = useState(); // Array of Objects
  const [allCasesLoading, setAllCasesLoading] = useState(false); // Boolean
  const [isEditable, setIsEditable] = useState(false); // Boolean
  const [caseId, setCaseId] = useState(0); // Integer
  const [hearingDate, setHearingDate] = useState(""); // String
  const [openAddCaseModal, setOpenAddCaseModal] = useState(false); // Boolean
  const [newCaseAdded, setNewCaseAdded] = useState(false); // Boolean
  const [caseDeleted, setCaseDeleted] = useState(false); // Boolean
  const [addCaseLoader, setAddCaseLoader] = useState(false); // Boolean

  const onlyWidth = useWindowWidth(); // Getting Sceen Width for Easy Mobile Changes

  const access_token = sessionStorage.getItem("access_token"); // Authorization Token

  const navigate = useNavigate(); // Navigation

  // Fetching Cases Stats
  const fetchCasesStats = useMemo(
    () => async () => {
      try {
        const response = await axiosInstance.get("sccourt/cases/stats/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        setCasesStats(response.data);
      } catch (err) {
        console.log(err);
      }
    },
    [access_token]
  );

  const fetchAllCases = useMemo(
    () => async () => {
      try {
        setAllCasesLoading(true);
        const response = await axiosInstance.get("sccourt/cases", {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(response.data);
        setAllCases(response.data.reverse());

        let timeout;
        if (
          response?.data[0]?.status === "RETRIEVING" ||
          response?.data[0]?.status === "STARTED" ||
          response?.data[0]?.status === "COMPLETED_MERGING" ||
          response?.data[0]?.status === "COMPLETED_EXTRACTION"
        ) {
          console.log("TIMEOUT CALLED");
          timeout = setTimeout(() => {
            setNewCaseAdded((prev) => !prev);
          }, 5000);
        }

        if (response?.data[0]?.status === "COMPLETED") {
          clearTimeout(timeout);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setAllCasesLoading(false);
      }
    },
    [access_token]
  );

  // useEffect to fetch data from APIs.
  useEffect(() => {
    fetchCasesStats();
    fetchAllCases();
  }, [fetchCasesStats, fetchAllCases, hearingDate, newCaseAdded, caseDeleted]);

  // Handle the Next Hearing Date Edit Click.
  const handleEditClick = (caseid, e) => {
    e.stopPropagation();
    setIsEditable(true);
    setCaseId(caseid);
  };

  // Handle the Cancel Button for Hearing Date Edit.
  const handleCancelEdit = () => {
    setIsEditable(false);
  };

  // Handle the Submission of Hearing Date Edit.
  const handleDateSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.put(
        `sccourt/cases/${caseId}/`,
        { next_hearing_date: e.target[0].value },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setHearingDate(
        moment(response.data.next_hearing_date).format("D MMMM YYYY")
      );
      setIsEditable(false);

      toast.success("Next Hearing Date updated successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error("Not able to update Next Hearing Date.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Addition of a Case
  const handleAddCase = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }
    setAddCaseLoader(true);
    try {
      const response = await axiosInstance.post("sccourt/cases/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setNewCaseAdded((prev) => !prev);
      setOpenAddCaseModal(false);
      // console.log(newCaseAdded);

      toast.success("Case Added Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(response);

      setTimeout(() => {
        setNewCaseAdded((prev) => !prev);
      }, 20000);
    } catch (err) {
      setOpenAddCaseModal(false);

      if (err.response.status === 400) {
        toast.error(err.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setAddCaseLoader(false);
    }
  };

  // Handle Deletion of a Case
  const handleCaseDelete = async (caseid, e) => {
    e.stopPropagation();
    try {
      await axiosInstance.delete(`sccourt/cases/${caseid}/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      toast.success("Case Deleted Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setCaseDeleted((prev) => !prev);
    } catch (err) {
      toast.error("Unable to delete this case.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Case Card Click to Open Case Summary.
  const handleCaseCardClick = (caseid) => {
    // console.log(caseid);
    // if (allCases[0]?.highcourtcase === caseid) {
    //   if (allCases[0]?.parties || allCases[0]?.status) {
    //     navigate(`/high-court-delhi/${caseid}`);
    //   }
    // } else {
    navigate(`/supreme-court/${caseid}`);
    // }
  };

  if (!allCases?.length) {
    return (
      <>
        {allCasesLoading ? (
          <Loader />
        ) : (
          <div className="h-screen w-screen flex flex-col gap-4 justify-center items-center bg-white dark:bg-dark-600 px-4">
            {/* {openAddCaseModal && ( */}
            <AddCase
              newCaseAdded={newCaseAdded}
              handleAddCase={handleAddCase}
              addCaseLoader={addCaseLoader}
            />
            {/* )} */}
            <div className="flex flex-col gap-4 items-center">
              <p className="dark:text-gray-200 font-semibold text-gray-700 text-center">
                You do not have any Supreme Court Case.
              </p>
              {/* <button
                onClick={() => setOpenAddCaseModal(true)}
                className="max-sm:text-xs px-5 py-2 bg-dark-500 text-gray-300 font-raleway font-semibold rounded-lg border border-dark-500 dark:bg-light-500 dark:text-black text-sm hover:shadow-[4px_4px_0px_0px_] transition duration-200"
              >
                Add New Case
              </button> */}
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="bg-white dark:bg-dark-600 dark:text-gray-200 pt-20 pb-4 relative">
      {/* Top Stats Box */}
      {onlyWidth > 768 ? (
        <div className="flex items-start flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 shadow-sm mx-6 px-6 py-8">
          <AddCase
            handleAddCase={handleAddCase}
            addCaseLoader={addCaseLoader}
            newCaseAdded={newCaseAdded}
            openAddCaseModal={openAddCaseModal}
            setOpenAddCaseModal={setOpenAddCaseModal}
          />
          <div className="flex gap-2">
            <StatBox title={"Total Cases"} value={casesStats.total_cases} />
            <StatBox title={"Pending Cases"} value={casesStats.pending_cases} />
            <StatBox
              title={"Disposed Cases"}
              value={casesStats.disposed_cases}
            />
            <StatBox
              title={"Status Not Available"}
              value={casesStats.not_available_cases}
            />
            <StatBox
              title={"Custom Cases"}
              value={casesStats.custom_status_cases}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-start flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 shadow-sm mx-6 px-6 max-md:px-3 py-8">
          <AddCase
            handleAddCase={handleAddCase}
            addCaseLoader={addCaseLoader}
            newCaseAdded={newCaseAdded}
            openAddCaseModal={openAddCaseModal}
            setOpenAddCaseModal={setOpenAddCaseModal}
          />
          <div className="p-2 rounded-lg flex w-full flex-col bg-gray-50 dark:bg-black/15 gap-2">
            <MobileStatBox
              color={"bg-[#000000]"}
              title={"Total Cases"}
              value={casesStats.total_cases}
            />
            <MobileStatBox
              color={"bg-[#299B00]"}
              title={"Pending Cases"}
              value={casesStats.pending_cases}
            />
            <MobileStatBox
              color={"bg-[#BA0101]"}
              title={"Disposed Cases"}
              value={casesStats.disposed_cases}
            />
            <MobileStatBox
              color={"bg-[#FF7700]"}
              title={"Status Not Available"}
              value={casesStats.not_available_cases}
            />
            <MobileStatBox
              color={"bg-[#FFBB00]"}
              title={"Custom Cases"}
              value={casesStats.custom_status_cases}
            />
          </div>
        </div>
      )}
      {/* Bottom Cases Box */}
      <div className="flex flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 shadow-sm mx-6 py-8">
        {/* All Cases Box */}
        <div className="flex flex-col gap-6 px-2 py-8 max-md:py-2">
          {allCases?.map((eachcase) => (
            <Case
              key={eachcase.id}
              eachcase={eachcase}
              handleEditClick={handleEditClick}
              handleCancelEdit={handleCancelEdit}
              handleDateSubmit={handleDateSubmit}
              handleCaseDelete={handleCaseDelete}
              handleCaseCardClick={handleCaseCardClick}
              isEditable={isEditable}
              caseId={caseId}
              allCases={allCases}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

// Statistic Box Component
const StatBox = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-2 justify-between border dark:border-gray-600 rounded-lg p-2 font-mulish w-[200px]">
      <p className="font-medium">{title}</p>
      <p className="text-3xl">{value ? value : 0}</p>
    </div>
  );
};

// Mobile Statistic Box Component
const MobileStatBox = ({ color, title, value }) => {
  return (
    <div className="p-2 flex justify-between bg-white dark:bg-gray-700 rounded-sm">
      <div className="flex gap-2 items-center">
        <div className={`${color} size-3 rounded-full`}></div>
        <p className="font-mulish text-bold text-sm">{title}</p>
      </div>
      <p className="font-mulish text-sm">{value ? value : 0}</p>
    </div>
  );
};

// Each Case Card Component
const Case = ({
  eachcase,
  handleEditClick,
  handleCancelEdit,
  handleDateSubmit,
  handleCaseDelete,
  handleCaseCardClick,
  isEditable,
  caseId,
  allCases,
}) => {
  return (
    <div
      onClick={() => handleCaseCardClick(eachcase.supremecourtcase)}
      className="flex flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 cursor-pointer shadow-sm mx-6 px-6 max-md:px-3 max-md:mx-2 py-4"
    >
      <div className="flex gap-8 max-md:gap-4 justify-between">
        <div className="truncate">
          <h6 className="font-medium truncate max-md:text-xs">
            {eachcase?.parties &&
            (eachcase?.status === "COMPLETED" ||
              eachcase?.status === "COMPLETED_MERGING" ||
              eachcase?.status === "COMPLETED_EXTRACTION")
              ? eachcase?.parties
              : eachcase?.parties === null && eachcase?.status === "COMPLETED"
              ? "XXXXX vs XXXXX (Restricted)"
              : "Please wait for few seconds, while we get your case details."}
          </h6>
        </div>
        <div className="flex items-center gap-4 max-md:gap-2">
          <p>{eachcase?.filing_date}</p>
          {eachcase?.case_status?.toLowerCase() === "pending" ||
          eachcase?.case_status?.toLowerCase() === "judgment reserved" ? (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-red-700">
              Pending
            </div>
          ) : eachcase?.case_status?.toLowerCase() === "disposed off" ||
            eachcase?.case_status?.toLowerCase() === "disposed" ? (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-green-800">
              Disposed
            </div>
          ) : (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-gray-500">
              Not Available
            </div>
          )}
          <div className="flex items-center">
            <Dialog>
              <DialogTrigger onClick={(e) => e.stopPropagation()}>
                <AiOutlineDelete className="text-lg max-md:text-base cursor-pointer select-none hover:opacity-60" />
              </DialogTrigger>
              <DialogContent onClick={(e) => e.stopPropagation()}>
                <DialogHeader>
                  <DialogTitle>Delete Case</DialogTitle>
                </DialogHeader>
                <div className="flex flex-col gap-4">
                  <p>Are you sure you want to delete this case?</p>
                  <div className="flex gap-2">
                    <button
                      className="px-6 py-1.5 bg-red-600 text-gray-200"
                      onClick={(e) => handleCaseDelete(eachcase.id, e)}
                    >
                      Delete
                    </button>
                    {/* <button className="px-6 py-1.5 bg-zinc-100 text-gray-700">
                      Cancel
                    </button> */}
                  </div>
                </div>
              </DialogContent>
            </Dialog>
          </div>
        </div>
      </div>
      <div className="flex max-md:flex-col max-md:gap-2 gap-8 text-sm">
        <div className="flex items-center gap-2 basis-[250px] max-md:basis-0">
          <PiBookOpenText className="max-md:text-xs" />
          <p className="max-md:text-xs">
            {eachcase?.dairy_number
              ? `${eachcase?.dairy_number}/${eachcase?.year}`
              : `${eachcase?.caseno}/${eachcase?.year}`}
          </p>
        </div>
        <div className="flex items-center gap-2 basis-[200px] max-md:basis-0">
          <IoLocationOutline className="max-md:text-xs" />
          <p className="max-md:text-xs">
            {eachcase?.court[0]?.toUpperCase() + eachcase?.court?.slice(1)}{" "}
            Court
          </p>
        </div>
        {isEditable && eachcase.id === caseId ? (
          <form
            className="flex gap-2"
            onSubmit={(e) => handleDateSubmit(e)}
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="date"
              className="bg-light-600 border border-[#d9d9d9] dark:border-gray-500 dark:text-black px-1 text-xs"
              required
            />
            <button
              type="submit"
              className="bg-blue-600 text-white px-2 text-xs rounded-sm"
            >
              Submit
            </button>
            <button
              className="bg-slate-200 px-2 dark:text-black text-xs font-medium rounded-sm"
              onClick={handleCancelEdit}
            >
              Cancel
            </button>
          </form>
        ) : (
          <div className="flex items-center gap-2">
            <CiCalendar className="max-md:text-xs" />
            <div
              className="flex items-center gap-2"
              onClick={(e) => handleEditClick(eachcase.id, e)}
            >
              <p className="max-md:text-xs">
                {eachcase.next_hearing_date
                  ? `Next Hearing - ${moment(eachcase.next_hearing_date).format(
                      "D MMMM YYYY"
                    )}`
                  : "Add next hearing date"}
              </p>
              <CiEdit className="cursor-pointer" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const AddCase = ({
  addCaseLoader,
  handleAddCase,
  newCaseAdded,
  openAddCaseModal,
  setOpenAddCaseModal,
}) => {
  return (
    <Dialog open={openAddCaseModal} onOpenChange={setOpenAddCaseModal}>
      <DialogTrigger className="max-sm:text-xs px-5 py-2 bg-dark-500 text-gray-300 font-raleway font-semibold rounded-lg border border-dark-500 dark:bg-light-500 dark:text-black text-sm hover:shadow-[4px_4px_0px_0px_] transition duration-200">
        Add New Case
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Case</DialogTitle>
          <DialogDescription>
            Add new case by giving the diary number of the case.
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="Diary Number">
          <TabsList>
            <TabsTrigger value="Diary Number">Diary Number</TabsTrigger>
            <TabsTrigger value="Case No">Case Number</TabsTrigger>
          </TabsList>
          <TabsContent value="Diary Number">
            <form
              className="w-full flex flex-col gap-4 mt-4"
              onSubmit={handleAddCase}
            >
              {/* <Select required name="case_type" autoComplete="off">
            <SelectTrigger>
              <SelectValue placeholder="Select Case Type*" />
            </SelectTrigger>
            <SelectContent>
              {caseTypes.map((caseType) => (
                <SelectItem key={caseType} value={caseType}>
                  {caseType}
                </SelectItem>
              ))}
            </SelectContent>
          </Select> */}
              <Input
                type="text"
                placeholder="Diary Number*"
                name="dairy_number"
                required
                autoComplete="off"
              />
              <Select required name="year" autoComplete="off" className="h-10">
                <SelectTrigger>
                  <SelectValue placeholder="Select Case Year*" />
                </SelectTrigger>
                <SelectContent className="max-h-[300px]">
                  {years.map((year) => (
                    <SelectItem key={year} value={`${year}`}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Input
                type="text"
                placeholder="Case Name"
                name="case_nickname"
                autoComplete="off"
              />
              <DialogFooter>
                <button
                  type="submit"
                  className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                >
                  {addCaseLoader ? (
                    <div className="flex gap-1 justify-center items-center">
                      <p>Adding Case...</p>
                      <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    "Continue to Add Case"
                  )}
                </button>
              </DialogFooter>
            </form>
          </TabsContent>
          <TabsContent value="Case No">
            <form
              className="w-full flex flex-col gap-4 mt-4"
              onSubmit={handleAddCase}
            >
              <Select required name="case_type" autoComplete="off">
                <SelectTrigger>
                  <SelectValue placeholder="Select Case Type*" />
                </SelectTrigger>
                <SelectContent>
                  {caseTypes.map((caseType) => (
                    <SelectItem key={caseType} value={caseType}>
                      {caseType}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Input
                type="text"
                placeholder="Case Number*"
                name="caseno"
                required
                autoComplete="off"
              />
              <Select required name="year" autoComplete="off" className="h-10">
                <SelectTrigger>
                  <SelectValue placeholder="Select Case Year*" />
                </SelectTrigger>
                <SelectContent className="max-h-[300px]">
                  {years.map((year) => (
                    <SelectItem key={year} value={`${year}`}>
                      {year}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Input
                type="text"
                placeholder="Case Name"
                name="case_nickname"
                autoComplete="off"
              />
              <DialogFooter>
                <button
                  type="submit"
                  className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                >
                  {addCaseLoader ? (
                    <div className="flex gap-1 justify-center items-center">
                      <p>Adding Case...</p>
                      <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    "Continue to Add Case"
                  )}
                </button>
              </DialogFooter>
            </form>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

// Loader Component
const Loader = () => {
  return (
    <div className="flex flex-row gap-2">
      <div className="w-4 h-4 rounded-full bg-red-500 animate-bounce"></div>
      <div className="w-4 h-4 rounded-full bg-red-500 animate-bounce [animation-delay:-.3s]"></div>
      <div className="w-4 h-4 rounded-full bg-red-500 animate-bounce [animation-delay:-.5s]"></div>
    </div>
  );
};

export default SupremeCourt;
