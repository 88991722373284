import React from "react";

import { useOutletContext } from "react-router-dom";

import ReactMarkdown from "react-markdown";

const CaseSummary = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  return (
    <div className="flex flex-col gap-4 px-6 pb-6 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
      {caseDetails?.status === "COMPLETED" && caseDetails?.complete_summary ? (
        <ReactMarkdown className="prose dark:prose-invert max-w-full">
          {caseDetails?.complete_summary}
        </ReactMarkdown>
      ) : caseDetails?.status === "COMPLETED" &&
        !caseDetails?.complete_summary ? (
        "No Case Summary Found."
      ) : (
        "Please wait, while we are generating your case summary..."
      )}
    </div>
  );
};

export default CaseSummary;
