import React, { useEffect, useState } from "react";
import "../assets/css/LandingPage.css";
import "@fortawesome/fontawesome-free/css/all.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import AOS from "aos";
import "aos/dist/aos.css";
import LazyLoad from "react-lazy-load";
import IntegratedWith from "./IntegratedWith";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode as jwt_decode } from "jwt-decode";
import axiosInstance from "../utils/axiosInstance";
import { useAuth } from "../context/Authcontext";
import indiaVector from "../assets/images/landingpage/indiaVector.svg";
import indiaVector2 from "../assets/images/landingpage/indiaVector2.svg";
import SupportrdWith from "./SupportedWith";
import nvidia from "../assets/images/landingpage/nvidia-bw.png";
import microsoft from "../assets/images/landingpage/microsoft-bw.png";
import nvidiaColored from "../assets/images/landingpage/nvidia.png";
import microsoftColored from "../assets/images/landingpage/microsoft.svg";
import nvidiaInv from "../assets/images/landingpage/nvidia-inverted.png";
import microsoftInv from "../assets/images/landingpage/microsoft-inverted.png";
import aws from "../assets/images/landingpage/aws.svg";

import { motion } from "framer-motion";
import { AuroraBackground } from "../components/ui/aurora-background";

const LandingPage = () => {
  const [headline, setHeadline] = useState("");
  const [email, setEmail] = useState(null);
  const navigate = useNavigate();
  const { login, isAuthenticated } = useAuth();
  const [isHovered, setIsHovered] = useState(false);

  // Window Width Used to show Slider or Not.
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  // Window Width Used to show Slider or Not.

  useEffect(() => {
    AOS.init({ duration: 2000 });
  }, []);

  useEffect(() => {
    const video = document.createElement("video");
    // video.src = botvid;
    video.preload = "auto"; // Preload the entire video
    video.onloadedmetadata = () => {
      console.log("Video metadata loaded");
      // Optionally handle other setup or state updates here
    };
  }, []);

  useEffect(() => {
    document.querySelector(".full-height").classList.add("loaded");
    const textToType =
      "Empowering Legal Professionals with AI-Powered Tools. Get instant legal insights, track your cases in real-time, and streamline your workflow with our easy-to-use platform.";
    let currentText = "";
    const typeHeadlineText = () => {
      currentText = textToType.substring(0, currentText.length + 1);
      setHeadline(currentText);

      if (currentText !== textToType) {
        setTimeout(typeHeadlineText, 25);
      }
    };

    typeHeadlineText();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      /* global google */
      const initializeGoogleSignIn = () => {
        google.accounts.id.initialize({
          client_id:
            "246105724090-95arttgdnp0sohiaqqtvpctr875kassq.apps.googleusercontent.com",
          callback: googleSuccess,
        });
        google.accounts.id.prompt(); // Display the One Tap prompt
      };

      const googleSuccess = (response) => {
        const credentialResponseDecode = jwt_decode(response.credential);
        const id_token = response.credential;
        // Using axios for sending data to the server
        axiosInstance
          .post(
            "api/google-login/",
            { id_token },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log("Server response:", response);
            sessionStorage.setItem("user-info", JSON.stringify(response.data));
            sessionStorage.setItem("access_token", response.data.access); // Make sure your backend is sending this data
            // Call login function if you have one, or handle the login state here
            login();
            navigate("/");
          })
          .catch((error) => {
            console.error("Error:", error);
          });
      };

      // Load the Google Identity Services library and initialize it
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = initializeGoogleSignIn;
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [navigate, isAuthenticated, login]);

  const handleButtonClick = (path) => {
    if (!isAuthenticated) {
      sessionStorage.setItem("intendedURL", path);
      navigate("/signup");
    } else {
      navigate(path);
    }
  };

  // const access_token = sessionStorage.getItem("access_token");
  // const createChatRoom = async () => {
  //   try {
  //     const response = await axiosInstance.post(
  //       "chatapp/chat-room/",
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${access_token}`,
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     console.log("Chat room created:", response.data);
  //     navigate("/talkaboutcase");
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  return (
    <>
      <AuroraBackground>
        <motion.div
          initial={{ opacity: 0.0, y: 40 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{
            delay: 0.0,
            duration: 0.8,
            ease: "easeInOut",
          }}
          className="full-height with-background w-full"
        >
          <div className="max-sm:gap-6 flex flex-col mx-auto items-center justify-center md:mt-36 mt-20 h-screen">
            <div
              className="flex flex-col w-full mx-auto md:mb-20 text-center"
              style={
                {
                  // backgroundImage: `url(${"https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/background.png"})`,
                  // backgroundSize: "conver",
                  // backgroundPosition: "0 100px",
                  // backgroundRepeat: "no-repeat",
                }
              }
            >
              <div className="w-full text-center">
                <h1 className="max-sm:text-5xl max-md:text-6xl font-tinos text-[100px] tracking-wide leading-[120px] w-full mx-auto text-center">
                  <span className="text-gray-700 dark:text-gray-300">Case</span>
                  <span className="text-gray-700 dark:text-gray-300">
                    Minister
                  </span>
                </h1>
              </div>
              <div className="two-para w-full">
                <p
                  // style={{
                  //   color: "black",
                  //   fontWeight: "500",
                  //   fontSize: "21px",
                  //   // height: "80px",
                  // }}
                  className="max-md:text-lg max-sm:text-base max-sm:px-5 text-gray-500 dark:text-gray-400 leading-9 tracking-wide text-center max-sm:w-full w-1/2 mx-auto"
                >
                  {/* {headline} */}
                  Empowering Legal Professionals with AI-Powered Tools. Get
                  instant legal insights, track your cases in real-time, and
                  streamline your workflow with our easy-to-use platform.
                </p>
                {email && <p>Welcome, {email}</p>}
              </div>
              <div className="max-sm:gap-2 max-sm:flex-col w-full mx-auto justify-center flex gap-5 font-semibold mt-6">
                {/* TYPE - 3 (Sketch Buttons) */}
                <Link to="/talkaboutcase">
                  <button
                    // onClick={createChatRoom}
                    className="max-sm:text-[11px] max-sm:px-4 max-sm:py-1.5 px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#313134] transition duration-200"
                  >
                    Use Lawiz - Legal Chatbot
                  </button>
                </Link>
                <Link to="/mycases">
                  <button className="max-sm:text-[11px] max-sm:py-1.5 max-sm:px-4 px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-sm hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#313134] transition duration-200">
                    AI-Based Case Management
                  </button>
                </Link>
              </div>
            </div>

            {/* <div className="roboimg" style={{ position: "relative" }}> */}
            {/* <div
              className="main-img-container"
              style={{ position: "relative" }}
            >
              <LazyLoad>
                <img
                  src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/main homepage vector.svg"
                  alt="Background"
                  className="landingpage-background-image"
                />
              </LazyLoad>
            </div>
            <div className="gif-container">
              <LazyLoad>
                <img
                  src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/landing page chatbot.gif"
                  alt="Animated robot gif"
                  className="overlay-image"
                />
              </LazyLoad>
            </div>
            <div className="top-container">
              <LazyLoad>
                <img
                  src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/robot.png"
                  alt="New top"
                  className="landing-top-image"
                />
              </LazyLoad>
            </div>
            <LazyLoad>
              <img
                src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/circles bg right.svg"
                alt="Description of the bottom"
                className="headline-image"
              />
            </LazyLoad> */}
            {/* </div> */}
            <div className="w-[90%] max-sm:gap-10 rounded-lg flex flex-col gap-2 pb-6">
              {width <= 700 ? (
                <IntegratedWith />
              ) : (
                <div className="flex items-center gap-6 justify-center">
                  <img
                    className="w-1/6 dark:hidden"
                    src={nvidiaColored}
                    alt="nvidia logo"
                  />
                  <img
                    className="w-1/6 dark:hidden"
                    src={microsoftColored}
                    alt="microsoft logo"
                  />
                  <img
                    className="w-1/6 hidden dark:block"
                    src={nvidiaInv}
                    alt="nvidia logo"
                  />
                  <img
                    className="w-1/6 hidden dark:block"
                    src={microsoftInv}
                    alt="microsoft logo"
                  />
                </div>
              )}
              <div className="max-sm:px-2 max-sm:text-base text-center px-10 font-raleway text-lg font-medium w-full mx-auto tracking-normal leading-8">
                <p
                  className="fade-in-text text-gray-600 dark:text-gray-400 max-sm:text-sm"
                  data-ios="true"
                >
                  Recognised by{" "}
                  <span className="text-gray-700 dark:text-gray-200 font-semibold">
                    NVIDIA Inception Program
                  </span>{" "}
                  and{" "}
                  <span className="text-gray-700 dark:text-gray-200 font-semibold">
                    {" "}
                    Microsoft For Startups{" "}
                  </span>{" "}
                  {/* and <span style={{ color: "#5D54F1" }}>Amazon Web Services,</span>{" "} */}
                  validating our innovative approach and growth potential. These
                  endorsements highlight our commitment to{" "}
                  <span className="text-gray-700 dark:text-gray-200 font-semibold">
                    excellence and industry leadership.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </motion.div>
      </AuroraBackground>
      <div className="max-md:flex-col flex relative items-center justify-around dark:bg-dark-600 bg-light-600 m-0 py-14 px-10">
        <div className="w-auto">
          <div className="">
            {/* <LazyLoad> */}
            <div>
              <img
                className="main-image1 hidden dark:block scale-[1.1]"
                // src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/indiaVector.svg"
                src={indiaVector}
                alt="Descriptive text"
              />
              <img
                className="main-image1 dark:hidden scale-[1.1]"
                // src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/indiaVector.svg"
                src={indiaVector2}
                alt="Descriptive text"
              />
              {/* <img className="main-image1-mobile" src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/mobile-hand-vector.png" alt="Descriptive text for the mobile image" /> */}
            </div>
            {/* </LazyLoad> */}
            <div className="overlay-text">
              <div className="fade-in-line">
                <h3 className="max-sm:text-[33px] max-sm:leading-[50px] text-[50px] leading-[60px] font-raleway font-extrabold tracking-wider">
                  <span className="dark:text-gray-500 text-gray-500">
                    Unprecedented <br /> Legal{" "}
                  </span>
                  <span className="dark:text-gray-300 text-gray-700">
                    Institution <br /> Support{" "}
                  </span>
                </h3>
                <p className="dark:text-gray-400 font-light font-mulish leading-[40px]">
                  Official Recognition of CaseMinister's potential to transform
                  legal practices​, covering multiple jurisdictions across
                  India.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-[40%] max-md:w-[100%]">
          <SupportrdWith />
        </div>
      </div>
    </>
  );
};

export default LandingPage;
