// import { useEffect, useRef } from "react";
// import { debounce } from "lodash";
// import notificationIcon from "../assets/images/navbar/notificationIcon.svg";
// import profileicon from "../assets/images/navbar/profileIcon.svg";
// import { FaBell } from "react-icons/fa";
// import NotificationDropdown from "./NotificationDropdown";
// import { useNavigate } from "react-router-dom";
// import { useCaseContext } from "../CaseContext";
// import { useAuth } from "../Authcontext";
// import "../assets/css/Navbar.css";
// import logo from "../assets/images/navbar/legal-tech-logo.svg";
// import "aos/dist/aos.css";
// import profileIcon from "../assets/images/navbar/profileVector.svg";
// import contactusIcon from "../assets/images/navbar/contactusIcon.svg";
// import signout from "../assets/images/Chatbot/Sign_out_squre.svg";
// import aiDrivenMobile from "../assets/images/navbar/ai-divien-chatbot-mobile.svg";
// import liveCaseTracking from "../assets/images/navbar/live-case-tracking-mobile.svg";
// import performanceAnalytics from "../assets/images/navbar/performance_analytics-mobile.svg";
// import documentationAndCustomization from "../assets/images/navbar/documentation_customization_mobile.svg";
// import perdective from "../assets/images/navbar/predictive_analysis_mobile.svg";
// import pressRelease from "../assets/images/navbar/press_release.svg";
// import faq from "../assets/images/navbar/faq.svg";
// import newsAndBlogs from "../assets/images/navbar/news.svg";
// import { AbstractPage } from "openai/core.mjs";

// const Navbar = () => {
//   const [scrolled, setScrolled] = useState(false);
//   const [clicked, setClicked] = useState(false);
//   const [dropdownOpen, setDropdownOpen] = useState(false);
//   const [dropdownOpen1, setDropdownOpen1] = useState(false);
//   const dropdownRef = useRef(null);
//   const navigate = useNavigate();
//   const [notificationDropdownOpen, setNotificationDropdownOpen] =
//     useState(false);
//   const notificationRef = useRef(null);
//   const {
//     unreadNotifications,
//     setUnreadNotifications,
//     fetchNotifications,
//     handleCategoryClick,
//     filteredNotifications,
//   } = useCaseContext();
//   const { login, isAuthenticated } = useAuth();

//   const handleScroll = debounce(() => {
//     setScrolled(window.scrollY > 0);
//   }, 100);

//   const access_token = sessionStorage.getItem("access_token");

//   useEffect(() => {
//     if (access_token) {
//       fetchNotifications();
//     }
//   }, [access_token]);

//   useEffect(() => {
//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   useEffect(() => {
//     const handleOutsideClick = (event) => {
//       if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
//         setDropdownOpen(false);
//         setDropdownOpen1(false);
//       }
//       if (
//         notificationRef.current &&
//         !notificationRef.current.contains(event.target)
//       ) {
//         setNotificationDropdownOpen(false);
//       }
//     };

//     document.addEventListener("click", handleOutsideClick);
//     return () => {
//       document.removeEventListener("click", handleOutsideClick);
//     };
//   }, []);

//   const handleClick = () => {
//     setClicked(!clicked);
//   };

//   const handleLogout = () => {
//     sessionStorage.clear();
//     navigate("/");
//     setClicked(!clicked);
//   };

//   const toggleDropdown = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setDropdownOpen(!dropdownOpen);
//   };

//   const toggleDropdown1 = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setDropdownOpen1(!dropdownOpen1);
//   };
//   const toggleNotificationDropdown = (event) => {
//     event.preventDefault();
//     event.stopPropagation();
//     setNotificationDropdownOpen(!notificationDropdownOpen);
//   };

//   const handleButtonClick = (path) => {
//     if (!isAuthenticated) {
//       sessionStorage.setItem("intendedURL", path);
//       navigate("/signup");
//     } else {
//       navigate(path);
//     }
//   };

//   return (
//     <>
//       <div className="main-nav" style={{ color: "blue" }}>
//         <nav className="nav-container">
//           <div className="main-logo">
//             <a href="/">
//               <img
//                 src={logo}
//                 alt="logo"
//                 style={{ height: "50px", width: "75px" }}
//               />
//             </a>
//           </div>
//           <div>
// <ul id="navbar" className={clicked ? "#navbar active" : "#navbar"}>
//   <li style={{ marginBottom: "0px" }}>
//     <a href="/">Home</a>
//   </li>

//   <li className="dropdown" style={{ marginBottom: "0px" }}>
//     <button onClick={toggleDropdown} className="dropbtn">
//       Features{" "}
//       <i
//         className={`fa ${
//           dropdownOpen ? "fa-caret-up" : "fa-caret-down"
//         }`}
//         style={{ color: "#5D54F1" }}
//       ></i>
//     </button>
//     {dropdownOpen && (
//       <div
//         ref={dropdownRef}
//         className={`dropdown-content ${
//           dropdownOpen ? "show-dropdown" : ""
//         }`}
//       >
//         <div
//           className="dropdown-menus"
//           onClick={() => handleButtonClick("/talkaboutcase")}
//         >
//           <a>
//             <img
//               src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop1.svg"
//               alt="AI-Driven Chatbot"
//               className="mobileImage"
//               loading="lazy"
//               style={{ width: "95px" }}
//             />
//           </a>
//           <img
//             src={aiDrivenMobile}
//             alt=""
//             className="navbarLaptopImage"
//           />
//           <button className="beta_access">BETA Access</button>
//           <p style={{ cursor: "pointer" }}>AI-Driven Chatbot</p>
//         </div>
//         <div
//           className="dropdown-menus"
//           onClick={() => handleButtonClick("/mycases")}
//         >
//           <a>
//             <img
//               src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop2.svg"
//               alt="Live Case Tracking"
//               className="mobileImage"
//               loading="lazy"
//             />
//           </a>
//           <img
//             src={liveCaseTracking}
//             alt=""
//             className="navbarLaptopImage"
//           />
//           <button className="beta_access">BETA Access</button>
//           <p
//             onClick={() => handleButtonClick("/mycases")}
//             style={{ cursor: "pointer" }}
//           >
//             Live Case Tracking
//           </p>
//         </div>
//         <div
//           className="dropdown-menus"
//           onClick={() => {
//             window.location.href = "/upload_judgement";
//           }}
//         >
//           <a>
//             <img
//               src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/document_analysis.svg"
//               alt="Performance Analytics"
//               className="mobileImage"
//               loading="lazy"
//             />
//           </a>
//           <img
//             src={performanceAnalytics}
//             alt=""
//             className="navbarLaptopImage"
//           />
//           <button className="beta_access">BETA Access</button>
//           <p style={{ cursor: "pointer" }}>Document Analysis</p>
//         </div>
//         {/* <div className='dropdown-menus' onClick={() => { window.location.href = '/performance-analytic--coming-soon'; }}>
//           <a>
//             <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop3.svg" alt="Performance Analytics" className="mobileImage" loading="lazy" />
//           </a>
//           <img src={performanceAnalytics} alt="" className="navbarLaptopImage" />
//           <button className='coming-soon-feature'>Coming Soon</button>
//           <p style={{ cursor: 'pointer' }}>
//             Performance Analytics
//           </p>
//         </div> */}
//         <div
//           className="dropdown-menus"
//           onClick={() => {
//             window.location.href =
//               "/documentation-and-customization-coming-soon";
//           }}
//         >
//           <a>
//             <img
//               src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop4.svg"
//               alt="Documentation & Customisation"
//               className="mobileImage"
//               loading="lazy"
//             />
//           </a>
//           <img
//             src={documentationAndCustomization}
//             alt=""
//             className="navbarLaptopImage"
//           />
//           <button className="coming-soon-feature">
//             Coming Soon
//           </button>
//           <p style={{ cursor: "pointer" }}>
//             Documentation & Customisation
//           </p>
//         </div>
//         {/* <div className='dropdown-menus' onClick={() => { window.location.href = '/predictive-analysis-coming-soon'; }}>
//           <a>
//             <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/document_analysis.svg" alt="Documentation & Customisation" className="mobileImage" loading="lazy" />
//           </a>
//           <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/document_analysis.svg" alt="" className="navbarLaptopImage" />
//           <button className='coming-soon-feature'>Coming Soon</button>
//           <p style={{ cursor: 'pointer' }}>
//             Predictive  Analysis
//           </p>
//         </div> */}
//       </div>
//     )}
//   </li>
//   <li>
//     <a href="/pricing">Pricing</a>
//   </li>

//   <li className="dropdown" style={{ marginBottom: "0px" }}>
//     <button onClick={toggleDropdown1} className="dropbtn">
//       About Us{" "}
//       <i
//         className={`fa ${
//           dropdownOpen1 ? "fa-caret-up" : "fa-caret-down"
//         }`}
//         style={{ color: "#5D54F1" }}
//       ></i>
//     </button>
//     {dropdownOpen1 && (
//       <div
//         ref={dropdownRef}
//         className={`dropdown-content1 ${
//           dropdownOpen ? "show-dropdown" : ""
//         }`}
//       >
//         <div className="dropdown-right">
//           <div className="dropdown-menus1">
//             <div
//               className="about-main-content"
//               onClick={() => {
//                 window.location.href = "/about-us";
//               }}
//             >
//               <div className="about_dropdown-right">
//                 <img
//                   src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/about_us.svg"
//                   alt=""
//                 />
//               </div>
//               <div className="about_dropdown-left">
//                 <h4>ABOUT US</h4>
//                 <p>Know our story</p>
//               </div>
//             </div>
//           </div>
//           <div className="dropdown-menus1">
//             <div
//               className="about-main-content"
//               onClick={() => {
//                 window.location.href = "/faq";
//               }}
//             >
//               <div className="about_dropdown-right">
//                 <img
//                   src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/faq.svg"
//                   alt=""
//                 />
//               </div>
//               <div className="about_dropdown-left">
//                 <h4>FAQ</h4>
//                 <p>Frequently Asked Questions</p>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="dropdown-left">
//           <div
//             className="dropdown-menus1"
//             style={{ border: "none" }}
//           >
//             <div
//               className="about-main-content"
//               onClick={() => {
//                 window.location.href = "/";
//               }}
//             >
//               <div className="about_dropdown-right">
//                 <img
//                   src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/press_release.svg"
//                   alt=""
//                 />
//               </div>
//               <div className="about_dropdown-left">
//                 <h4>PRESS RELEASE</h4>
//                 <p>New announcement for media</p>
//               </div>
//             </div>
//           </div>
//           <div
//             className="dropdown-menus1"
//             style={{ border: "none" }}
//           >
//             <div
//               className="about-main-content"
//               onClick={() => {
//                 window.location.href = "/";
//               }}
//             >
//               <div className="about_dropdown-right">
//                 <img
//                   src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/news.svg"
//                   alt=""
//                 />
//               </div>
//               <div className="about_dropdown-left">
//                 <h4>NEWS & BLOGS</h4>
//                 <p>Latest updates & insights</p>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     )}
//   </li>
//   <div className="menu-items-mobile">
//     <li className="contact">
//       <img src={contactusIcon} alt="" />
//     </li>
//   </div>
//   <div className="logout-container">
//     <button className="logout-button" onClick={handleLogout}>
//       <div className="text">Log Out</div>
//       <div className="logout-img">
//         <img src={signout} alt="sign out" />
//       </div>
//     </button>
//     <div className="profileImag">
//       <a href="contactus" style={{ textDecoration: "none" }}>
//         <span
//           style={{
//             color: "#5d54f1",
//             fontSize: "18px",
//             fontWeight: "500",
//             margin: "10px",
//           }}
//         >
//           Contact Us
//         </span>
//         <img src={contactusIcon} alt="" />
//       </a>
//     </div>
//   </div>
// </ul>
//             {!sessionStorage.getItem("isAuthenticated") ? (
//               <>
//                 <div className="login-signup1">
//                   <a href="/signup">
//                     <button className="signup-button">Signup</button>
//                   </a>
//                   <a href="/login">
//                     <button className="nav-login">Login</button>
//                   </a>
//                 </div>
//               </>
//             ) : (
//               <div className="login-signup2">
//                 <li>
//                   <a href="/user-profile">
//                     <img src={profileIcon} alt="" className="profileImg" />
//                   </a>
//                 </li>
//                 <li>
//                   <img
//                     src={notificationIcon}
//                     alt=""
//                     className="notificationImage"
//                     onClick={toggleNotificationDropdown}
//                     style={{ cursor: "pointer" }}
//                   />
//                   {unreadNotifications.length > 0 && (
//                     <span className="notification-badge">
//                       {unreadNotifications.length}
//                     </span>
//                   )}
//                   <NotificationDropdown
//                     isOpen={notificationDropdownOpen}
//                     toggleDropdown={toggleNotificationDropdown}
//                   />
//                 </li>
//               </div>
//             )}
//           </div>
//           <div className="left-corner">
//             <div className="menu-items">
//               <li>
//                 <a href="/contactus">
//                   <img src={contactusIcon} alt="" />
//                 </a>
//               </li>
//             </div>
//             {!sessionStorage.getItem("isAuthenticated") ? (
//               <>
//                 <div className="login-signup">
//                   <a href="/signup">
//                     <button className="signup-button">Signup</button>
//                   </a>
//                   <a href="/login">
//                     <button className="nav-login">Login</button>
//                   </a>
//                 </div>
//               </>
//             ) : (
//               <div className="login-signup">
//                 {/* ({unreadNotifications.length}) */}
//                 <li className="dropdown" onClick={toggleNotificationDropdown}>
//                   <img
//                     src={notificationIcon}
//                     alt="Notifications"
//                     className="navbarImage"
//                     style={{ marginRight: "-13px", cursor: "pointer" }}
//                   />
//                   {unreadNotifications.length > 0 && (
//                     <span className="notification-badge">
//                       {unreadNotifications.length}
//                     </span>
//                   )}
//                   <NotificationDropdown
//                     isOpen={notificationDropdownOpen}
//                     toggleDropdown={toggleNotificationDropdown}
//                   />
//                 </li>
//                 <li>
//                   <a href="/user-profile">
//                     <img src={profileicon} alt="" />
//                   </a>
//                 </li>
//                 <a href="/" onClick={handleLogout}>
//                   <button className="nav-login">Logout</button>
//                 </a>
//               </div>
//             )}
//           </div>

//           <div id="mobile" onClick={handleClick}>
//             <i
//               id="bar"
//               className={clicked ? "fas fa-times" : "fas fa-bars"}
//             ></i>
//           </div>
//         </nav>
//       </div>
//     </>
//   );
// };

// export default Navbar;

/* ---------------------- ACETERNITY NAVBAR ---------------------- */

// React utils
import React, { useState, useEffect, useRef, useContext } from "react";

// Assets & Icons
import contactusIcon from "../assets/images/navbar/contactusIcon.svg";
import signout from "../assets/images/Chatbot/Sign_out_squre.svg";
import logo from "../assets/images/navbar/legal-tech-logo.svg";
import { IoMoon, IoSunny } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { BiSolidUser } from "react-icons/bi";
// import ShadCN from "/images/shadcn1.png";

// Mobile Navbar Images
import ChatbotIcon from "../assets/images/mobile-navbar/Chatbot.svg";
import CaseManagement from "../assets/images/mobile-navbar/CaseManagement.svg";
import JudgementSearch from "../assets/images/mobile-navbar/JudgementSearch.svg";
import DocumentAnalysis from "../assets/images/mobile-navbar/DocumentAnalysis.svg";
import ChatbotDesktop from "../assets/images/navbar/Chatbot.svg";
import CaseTrackingDesktop from "../assets/images/navbar/CaseTracking.svg";
import JudgementSearchDesktop from "../assets/images/navbar/JudgementSearch.svg";
import DocumentAnalysisDesktop from "../assets/images/navbar/DocumentAnalysis.svg";
import AboutUs from "../assets/images/mobile-navbar/AboutUs.svg";
import FAQ from "../assets/images/mobile-navbar/FAQ.svg";
import PressRelease from "../assets/images/mobile-navbar/PressRelease.svg";
import ContactUs from "../assets/images/mobile-navbar/ContactUs.svg";

// Debounce Library
import { debounce } from "lodash";

// Components
import NotificationDropdown from "./NotificationDropdown";
import { ThemeContext } from "../context/ThemeContext";

// Contexts
import { useAuth } from "../context/Authcontext";
import { useCaseContext } from "../context/CaseContext";

// React Router
import { useNavigate, Link } from "react-router-dom";

// Aceternity UI Navbar
import {
  HoveredLink,
  Menu,
  MenuItem,
  ProductItem,
} from "../components/ui/navbar-menu";
import { cn } from "../utils/utils";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../components/ui/popover";

// Navbar Container
export default function NavbarDemo() {
  return (
    <div className="relative w-full flex items-center justify-center">
      <Navbar className="top-0 shadow-sm" />
    </div>
  );
}

// Navbar Component
function Navbar({ className }) {
  const [scrolled, setScrolled] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [notificationDropdownOpen, setNotificationDropdownOpen] =
    useState(false);
  const notificationRef = useRef(null);
  const {
    unreadNotifications,
    setUnreadNotifications,
    fetchNotifications,
    handleCategoryClick,
    filteredNotifications,
  } = useCaseContext();
  const { login, isAuthenticated } = useAuth();

  // Handle Theme Toggling
  const { themeToggle, handleThemeToggle } = useContext(ThemeContext);

  const handleScroll = debounce(() => {
    setScrolled(window.scrollY > 0);
  }, 100);

  const access_token = sessionStorage.getItem("access_token");

  useEffect(() => {
    if (access_token) {
      fetchNotifications();
    }
  }, [access_token]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        setDropdownOpen1(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setNotificationDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/");
    setClicked(!clicked);
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdown1 = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen1(!dropdownOpen1);
  };
  const toggleNotificationDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setNotificationDropdownOpen(!notificationDropdownOpen);
  };

  const handleButtonClick = (path) => {
    if (!isAuthenticated) {
      sessionStorage.setItem("intendedURL", path);
      navigate("/signup");
    } else {
      navigate(path);
    }
  };

  const [active, setActive] = useState(null);

  // User Initials
  const userInfo =
    sessionStorage.getItem("user-info") &&
    JSON.parse(sessionStorage.getItem("user-info"));

  const firstInitial = userInfo
    ? userInfo[0]?.user?.first_name !== ""
      ? userInfo[0]?.user?.first_name?.substring(0, 1)
      : "C"
    : "C";

  const lastInitial = userInfo
    ? userInfo[0]?.user?.last_name !== ""
      ? userInfo[0]?.user?.last_name?.substring(0, 1)
      : "M"
    : "M";

  return (
    <div className={cn("fixed inset-x-0 w-full mx-auto z-50", className)}>
      <Menu setActive={setActive}>
        <div className="flex items-center gap-3">
          <div id="mobile" className="md:hidden visible" onClick={handleClick}>
            <i
              id="bar"
              className={`${
                clicked ? "fas fa-times" : "fas fa-bars"
              } w-3 cursor-pointer dark:text-white`}
            ></i>
          </div>
          <Link to="/">
            <img src={logo} alt="logo" className="w-[75px] h-[50px]" />
          </Link>
        </div>
        <div className="md:flex gap-10 max-lg:gap-5 hidden">
          <Link to="/" className="hover:no-underline">
            <MenuItem setActive={setActive} item="Home"></MenuItem>
          </Link>
          <MenuItem
            setActive={setActive}
            active={active}
            item="Features"
            // arrow={true}
          >
            <div className="text-sm grid grid-cols-2 gap-x-0 max-lg:gap-x-0 gap-3">
              <ProductItem
                title="LaWiz Chatbot"
                href="/talkaboutcase"
                src={ChatbotDesktop}
                // src="https://assets.aceternity.com/demos/algochurn.webp"
                // src="/images/ChatbotDark.png"
                description="Get answers to your legal queries with our AI-driven chatbot."
              />
              <ProductItem
                title="AI-Based Case Management"
                href="/mycases"
                src={CaseTrackingDesktop}
                // src="https://assets.aceternity.com/demos/tailwindmasterkit.webp"
                // src="/images/CaseTrackingDark.png"
                description="Track your Case across all courts, get orders and Summaries, Talk to your case."
              />
              <ProductItem
                title="Judgement Search"
                href="/judgement-search"
                src={JudgementSearchDesktop}
                // src="https://assets.aceternity.com/demos/Screenshot+2024-02-21+at+11.47.07%E2%80%AFPM.png"
                // src="/images/shadcn1.png"
                description="Search for your Case and get the insights of your Court Room Trials."
              />
              <ProductItem
                title="Document Analysis"
                href="/upload_judgement"
                src={DocumentAnalysisDesktop}
                // src="https://assets.aceternity.com/demos/Screenshot+2024-02-21+at+11.51.31%E2%80%AFPM.png"
                description="Get AI-driven analysis and detailed insights for your documents."
              />
              {/* <ProductItem
                title="Documentation"
                href="/documentation-and-customization-coming-soon"
                src="https://assets.aceternity.com/demos/Screenshot+2024-02-21+at+11.47.07%E2%80%AFPM.png"
                description="Craft personalised legal documents effortlessly with our guided tools."
              /> */}
            </div>
          </MenuItem>
          <MenuItem
            setActive={setActive}
            active={active}
            item="Company"
            // arrow={true}
          >
            <div className="flex flex-col space-y-4 text-sm">
              <HoveredLink to="/about-us">About Us</HoveredLink>
              <HoveredLink to="/faq">FAQs</HoveredLink>
              <HoveredLink to="/#in-the-news">Press Release</HoveredLink>
              {/* <HoveredLink to="/">News & Blogs</HoveredLink> */}
              <HoveredLink to="/contactus">Contact Us</HoveredLink>
            </div>
          </MenuItem>
          <Link to="/pricing" className="hover:no-underline">
            <MenuItem setActive={setActive} item="Pricing"></MenuItem>
          </Link>
        </div>
        <div>
          {!sessionStorage.getItem("isAuthenticated") ? (
            <>
              <div className="login-signup flex gap-3 items-center">
                <div className="select-none mr-2">
                  <div className="cursor-pointer" onClick={handleThemeToggle}>
                    {!themeToggle && <IoMoon className="text-xl" />}
                    {themeToggle && <IoSunny className="fill-white text-xl" />}
                  </div>
                </div>
                <a href="/signup">
                  <button className="bg-light-500 dark:bg-dark-500 dark:text-gray-300 dark:border-dark-500 font-raleway text-sm font-semibold hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2.5px_2.5px_0px_0px_#313134] transition duration-200 border px-3 py-1 hover:text-black">
                    Signup
                  </button>
                </a>
                <a href="/login">
                  <button className="bg-light-500 dark:bg-dark-500 dark:text-gray-300 dark:border-dark-500 font-raleway text-sm font-semibold hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2.5px_2.5px_0px_0px_#313134] transition duration-200 border px-3 py-1 hover:text-black">
                    Login
                  </button>
                </a>
              </div>
            </>
          ) : (
            <div className="login-signup flex flex-row items-center max-lg:gap-4 gap-[30px]">
              {/* ({unreadNotifications.length}) */}
              <div className="select-none">
                <div className="cursor-pointer" onClick={handleThemeToggle}>
                  {!themeToggle && <IoMoon className="text-xl" />}
                  {themeToggle && <IoSunny className="fill-white text-xl" />}
                </div>
              </div>
              <div className="flex items-center max-lg:gap-4 gap-[30px]">
                <li
                  className="dropdown list-none cursor-pointer relative"
                  onClick={toggleNotificationDropdown}
                >
                  <Popover>
                    <PopoverTrigger>
                      <FaBell className="text-xl dark:text-whiteborder mt-1.5" />
                    </PopoverTrigger>
                    {unreadNotifications.length > 0 && (
                      <p className="flex justify-center items-center text-xs font-semibold absolute bg-red-700 text-white rounded-full h-5 w-5 top-[-10px] left-3">
                        {unreadNotifications.length}
                      </p>
                    )}
                    <PopoverContent className="w-0 h-0">
                      <NotificationDropdown
                        isOpen={notificationDropdownOpen}
                        toggleDropdown={toggleNotificationDropdown}
                      />
                    </PopoverContent>
                  </Popover>
                </li>
                <li className="list-none">
                  <Popover>
                    <PopoverTrigger>
                      {/* <a href="/user-profile"> */}
                      {firstInitial && lastInitial ? (
                        <div className="bg-gray-300 dark:bg-gray-600 text-sm rounded-full p-2 flex select-none">
                          <p>{firstInitial}</p>
                          <p>{lastInitial}</p>
                        </div>
                      ) : (
                        <FaUser className="text-xl dark:text-white" />
                      )}
                      {/* </a> */}
                    </PopoverTrigger>
                    <PopoverContent
                      sideOffset={12}
                      className="w-28 py-1 px-1 flex flex-col dark:text-gray-200 dark:bg-dark-600 bg-light-600 text-gray-700"
                    >
                      <a
                        className="flex gap-2 items-center hover:dark:bg-dark-400 hover:bg-gray-200 px-2 py-1.5 rounded-md"
                        href="/user-profile"
                      >
                        <BiSolidUser />
                        <p>Account</p>
                      </a>
                      <a
                        className="flex gap-2 items-center hover:dark:bg-dark-400 hover:bg-gray-200 px-2 py-1.5 rounded-md"
                        href="/"
                        onClick={handleLogout}
                      >
                        <BiLogOut />
                        <p>Logout</p>
                      </a>
                    </PopoverContent>
                  </Popover>
                </li>
              </div>
              {/* <a href="/" onClick={handleLogout} className="select-none">
                <button className="bg-light-500 dark:bg-dark-500 dark:text-gray-300 dark:border-dark-500 font-raleway text-sm font-semibold hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2.5px_2.5px_0px_0px_#313134] transition duration-200 border px-3 py-1 hover:text-black">
                  Logout
                </button>
              </a> */}
            </div>
          )}
        </div>
      </Menu>

      {/* For Mobile Screens */}
      <div
        className={`${
          clicked ? "visible" : "hidden"
        } w-full h-[calc(100vh-84px)] bg-light-600 px-4 py-4 flex flex-col justify-between dark:bg-dark-600 dark:text-gray-300`}
      >
        <ul
          id="navbar"
          className="flex flex-col gap-6 font-semibold tracking-wide"
        >
          {/* Home / No Dropdown */}
          <li onClick={() => setClicked(false)}>
            <a href="/">Home</a>
          </li>

          {/* Features / Dropdown */}
          <li className="dropdown flex flex-col gap-3 justify-center">
            <button
              onClick={toggleDropdown}
              className="dropbtn flex items-center gap-2"
            >
              Features{" "}
              <i
                className={`fa ${
                  dropdownOpen ? "fa-caret-down" : "fa-caret-right"
                } dark:text-white text-black`}
              ></i>
            </button>
            {dropdownOpen && (
              <div
                ref={dropdownRef}
                className={`dropdown-content ${
                  dropdownOpen ? "show-dropdown" : ""
                } flex flex-col gap-7 justify-center relative left-5`}
              >
                <div
                  className="dropdown-menus flex items-center gap-4 cursor-pointer"
                  onClick={() => {
                    handleButtonClick("/talkaboutcase");
                    setClicked(false);
                  }}
                >
                  <div>
                    <img
                      src={ChatbotIcon}
                      alt="AI-Driven Chatbot"
                      className="mobileImage w-5 dark:invert-[1]"
                      loading="lazy"
                    />
                  </div>
                  {/* <button className="beta_access">BETA Access</button> */}
                  <p style={{ cursor: "pointer" }}>LaWiz Chatbot</p>
                </div>
                <div
                  className="dropdown-menus flex items-center gap-4 cursor-pointer"
                  onClick={() => {
                    handleButtonClick("/mycases");
                    setClicked(false);
                  }}
                >
                  <div>
                    <img
                      src={CaseManagement}
                      alt="Live Case Tracking"
                      className="mobileImage w-5 dark:invert-[1]"
                      loading="lazy"
                    />
                  </div>
                  {/* <button className="beta_access">BETA Access</button> */}
                  <p
                    onClick={() => handleButtonClick("/mycases")}
                    style={{ cursor: "pointer" }}
                  >
                    AI-Based Case Management
                  </p>
                </div>
                <div
                  className="dropdown-menus flex items-center gap-4 cursor-pointer"
                  onClick={() => {
                    window.location.href = "/judgement-search";
                  }}
                >
                  <div>
                    <img
                      src={JudgementSearch}
                      alt="Judgement Search"
                      className="mobileImage w-5 dark:invert-[1]"
                      loading="lazy"
                    />
                  </div>
                  {/* <button className="coming-soon-feature">Coming Soon</button> */}
                  <p>Judgement Search</p>
                </div>
                <div
                  className="dropdown-menus flex items-center gap-4 cursor-pointer"
                  onClick={() => {
                    window.location.href = "/upload_judgement";
                    setClicked(false);
                  }}
                >
                  <div>
                    <img
                      src={DocumentAnalysis}
                      alt="Document Analysis"
                      className="mobileImage w-5 dark:invert-[1]"
                      loading="lazy"
                    />
                  </div>
                  {/* <button className="beta_access">BETA Access</button> */}
                  <p style={{ cursor: "pointer" }}>Document Analysis</p>
                </div>
                {/* <div
                  className="dropdown-menus"
                  onClick={() => {
                    window.location.href = "/performance-analytic--coming-soon";
                  }}
                >
                  <a>
                    <img
                      src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/drop3.svg"
                      alt="Performance Analytics"
                      className="mobileImage"
                      loading="lazy"
                    />
                  </a>
                  <img
                    src={performanceAnalytics}
                    alt=""
                    className="navbarLaptopImage"
                  />
                  <button className="coming-soon-feature">Coming Soon</button>
                  <p style={{ cursor: "pointer" }}>Performance Analytics</p>
                </div> */}

                {/* <div
                  className="dropdown-menus"
                  onClick={() => {
                    window.location.href = "/predictive-analysis-coming-soon";
                  }}
                >
                  <a>
                    <img
                      src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/document_analysis.svg"
                      alt="Documentation & Customisation"
                      className="mobileImage"
                      loading="lazy"
                    />
                  </a>
                  <img
                    src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/document_analysis.svg"
                    alt=""
                    className="navbarLaptopImage"
                  />
                  <button className="coming-soon-feature">Coming Soon</button>
                  <p style={{ cursor: "pointer" }}>Predictive Analysis</p>
                </div> */}
              </div>
            )}
          </li>

          {/* Pricing / No Dropdown */}
          <li>
            <a href="/pricing">Pricing</a>
          </li>

          {/* Company / No Dropdown */}
          <li className="dropdown flex flex-col gap-3 justify-center font-semibold">
            <button
              onClick={toggleDropdown1}
              className="dropbtn flex items-center gap-2"
            >
              Company{" "}
              <i
                className={`fa ${
                  dropdownOpen1 ? "fa-caret-down" : "fa-caret-right"
                } dark:text-white text-black`}
              ></i>
            </button>
            {dropdownOpen1 && (
              <div
                ref={dropdownRef}
                className={`dropdown-content1 ${
                  dropdownOpen ? "show-dropdown" : ""
                } flex flex-col gap-7 justify-center relative left-5`}
              >
                <div className="dropdown-menus1 cursor-pointer">
                  <div
                    className="about-main-content flex items-center gap-4"
                    onClick={() => {
                      window.location.href = "/about-us";
                    }}
                  >
                    <div className="about_dropdown-right">
                      <img
                        src={AboutUs}
                        alt="about us"
                        className="w-5 dark:invert-[1]"
                      />
                    </div>
                    <div className="about_dropdown-left">
                      <p>About Us</p>
                      {/* <p>Know our story</p> */}
                    </div>
                  </div>
                </div>
                <div className="dropdown-menus1 cursor-pointer">
                  <div
                    className="about-main-content flex items-center gap-4"
                    onClick={() => {
                      window.location.href = "/faq";
                    }}
                  >
                    <div className="about_dropdown-right">
                      <img
                        src={FAQ}
                        alt="faq"
                        className="w-5 dark:invert-[1]"
                      />
                    </div>
                    <div className="about_dropdown-left">
                      <p>FAQ</p>
                      {/* <p>Frequently Asked Questions</p> */}
                    </div>
                  </div>
                </div>
                <div className="dropdown-menus1 cursor-pointer">
                  <div
                    className="about-main-content flex items-center gap-4"
                    onClick={() => {
                      window.location.href = "/";
                    }}
                  >
                    <div className="about_dropdown-right">
                      <img
                        src={PressRelease}
                        alt="press release"
                        className="w-5 dark:invert-[1]"
                      />
                    </div>
                    <div className="about_dropdown-left">
                      <p>Press Release</p>
                      {/* <p>New announcement for media</p> */}
                    </div>
                  </div>
                </div>
                <div className="dropdown-menus1 cursor-pointer">
                  <div
                    className="about-main-content flex items-center gap-4"
                    onClick={() => {
                      window.location.href = "/contactus";
                    }}
                  >
                    <div className="about_dropdown-right">
                      <img
                        src={ContactUs}
                        alt="contact-us"
                        className="w-5 dark:invert-[1]"
                      />
                    </div>
                    <div className="about_dropdown-left">
                      <p>Contact Us</p>
                      {/* <p>Latest updates & insights</p> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </li>
        </ul>
        <div className="flex items-center justify-between">
          <div className="logout-container">
            <button className="flex gap-2 font-semibold" onClick={handleLogout}>
              <div className="text">Log Out</div>
              <div className="logout-img">
                <img src={signout} className="dark:invert-[1]" alt="sign out" />
              </div>
            </button>
          </div>
          <div className="profileImag">
            <a
              href="contactus"
              className="flex gap-2 decoration-none no-underline"
            >
              <span className="">Contact Us</span>
              <img src={contactusIcon} className="dark:invert-[1]" alt="" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
