import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./Protectedroute";
import { ToastContainer } from "react-toastify";
// import Spinner from "./Component/Spinner";

// import NavbarDemo from "./Component/Navbar";

// Directly import components
import LandingPage from "./Component/LandingPage";
import SecondaryNavbar from "./Component/SecondaryNavbar";
import Ourservices from "./Component/Ourservices";
import Blog from "./Component/Blog";
import Testimonial1 from "./Component/Testimonial1";
import Homepage from "./Component/Homepage";
import ChatBotParent from "./Component/ChatBotParent";
import CaseDetails from "./Component/CaseDetails";
import Navbar from "./Component/Navbar";
import Mycases from "./Component/Mycases";
import NewLogin from "./Component/NewLogin";
import Newsignup from "./Component/Newsignup";
import Searchlawyer from "./Component/Searchlawyer";
import Lawyerfilter from "./Component/Lawyerfilter";
// import UserProfile from "./Component/Userprofile";
import PrivilegedContent from "./Component/PrivilegedContent";
import Faq from "./Component/Faq";
// import NonPrivilegedPage from "./Component/NonPrivilegedPage";
import Pricing from "./Component/Pricing/Pricing";
import ContactUs from "./Component/ContactUs";
import LawyerProfile from "./Component/LawyerProfile";
import CourtSelection from "./Component/CourtSelection";
// import HighCourt from "./Component/HighCourt";
import HighCourtCaseDetails from "./Component/HighCourtCaseDetails";
import HighCourtPriviledge from "./Component/HighCourtPriviledge";
import { CaseProvider } from "./context/CaseContext";
import ReactGA from "react-ga4";
import ComingSoon from "./Component/ComingSoon";
import PerformanceAnalytics from "./Component/PerformanceAnalytics";
import DocumentationAndCustomization from "./Component/DocumentationAndCustomization";
import ExpertLawyerMatching from "./Component/ExpertLawyerMatching";
import PredictiveAnalysis from "./Component/PredictiveAnalysis";
import { NotificationProvider } from "./context/NotificationContext";
import BetaNotification from "./Component/BetaNotification";
import PrivacyPolicy from "./Component/PrivacyPolicy";
import TermsAndConditions from "./Component/TermsAndCondition";
import UploadJudgement from "./Component/UploadJudgement";
import { FolderProvider } from "./context/FolderContext";
import WaitList from "./Component/WaitList";
import SupremeCourtPrivilede from "./Component/SupremeCourtPrivilede";
import PanjabAndHaraynaHC from "./Component/PanjabAndHaraynaHC";
import Allhighcourt from "./Component/Allhighcourt";
import Allhighcourtprivildge from "./Component/Allhighcourtprivildge";
import UserDashboard from "./Component/UserDashboard";
import Aboutus from "./Component/Aboutus";
import ScrollToHashElement from "@cascadia-code/scroll-to-hash-element";
import SupremeCoutDetail from "./Component/SupremeCoutDetail";
import PasswordReset from "./Component/PasswordReset";
import HighCourtOfDelhi from "./Component/HighCourtDelhi/HighCourtOfDelhi";
import HighCourtDelhiDetails from "./Component/HighCourtDelhi/HighCourtDelhiDetails";
import CaseSummary from "./Component/HighCourtDelhi/CaseSummary";
import Timeline from "./Component/HighCourtDelhi/Timeline";
import OrderSheet from "./Component/HighCourtDelhi/OrderSheet";
import Notes from "./Component/HighCourtDelhi/Notes";
import LegalCaseSearch from "./Component/LegalCaseSearch/LegalCaseSearch";
import CaseSearchDetails from "./Component/LegalCaseSearch/CaseSearchDetails";
import SearchResults from "./Component/LegalCaseSearch/SearchResults";
import FeedbackForm from "./Component/FeedbackForm/FeedbackForm";
import UserProfile from "./Component/Profiles/UserProfile";
import JudgeAnalytics from "./Component/HighCourtDelhi/JudgeAnalytics";
import OpposingCounselAnalytics from "./Component/HighCourtDelhi/OpposingCounselAnalytics";
import SimilarCases from "./Component/HighCourtDelhi/SimilarCases";
import HighCourtOfPH from "./Component/HighCourtOfPH/HighCourtOfPH";
import HighCourtPHDetails from "./Component/HighCourtOfPH/HighCourtPHDetails";
import DistrictCourts from "./Component/DistrictCourts/DistrictCourts";
import DistrictCourtDetails from "./Component/DistrictCourts/DistrictCourtDetails";
import SupremeCourt from "./Component/SupremeCourt/SupremeCourt";
import SupremeCourtDetails from "./Component/SupremeCourt/SupremeCourtDetails";
import AllHighCourt from "./Component/AllHighCourt/AllHighCourt";
import AllHighCourtDetails from "./Component/AllHighCourt/AllHighCourtDetails";
import AllCasesDashboard from "./Component/AllCasesDashboard/AllCasesDashboard";
import Dashboard from "./Component/AllCasesDashboard/Dashboard/Dashboard";
import CauseList from "./Component/AllCasesDashboard/CauseList/CauseList";
import Clients from "./Component/AllCasesDashboard/Clients/Clients";
import Acts from "./Component/AllCasesDashboard/Acts/Acts";
import Share from "./Component/AllCasesDashboard/Share/Share";

ReactGA.initialize("G-FJ8EC6ZWVF");

const App = () => {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div>
      <NotificationProvider>
        {/* <BetaNotification /> */}
        {/* <FeedbackForm /> */}
        <ToastContainer />
        <FolderProvider>
          <CaseProvider>
            <BrowserRouter>
              <ScrollToHashElement />
              <Navbar />
              <Routes>
                <Route path="/" element={<Homepage />} />
                <Route path="/login" element={<NewLogin />} />
                <Route path="/signup" element={<Newsignup />} />
                {/* <Route path="/navbar" element={<NavbarDemo />} /> */}
                <Route path="/ourservices" element={<Ourservices />} />
                <Route
                  path="/performance-analytic--coming-soon"
                  element={<PerformanceAnalytics />}
                />
                <Route
                  path="/documentation-and-customization-coming-soon"
                  element={<DocumentationAndCustomization />}
                />
                <Route
                  path="/expert-lawyer-matching-coming-soon"
                  element={<ExpertLawyerMatching />}
                />
                <Route
                  path="/predictive-analysis-coming-soon"
                  element={<PredictiveAnalysis />}
                />
                <Route
                  path="/searchlawyer"
                  element={
                    <ProtectedRoute>
                      {" "}
                      <Searchlawyer />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/mycases"
                  element={
                    <ProtectedRoute>
                      <Mycases />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/Filteredlawyer"
                  element={
                    <ProtectedRoute>
                      <Lawyerfilter />
                    </ProtectedRoute>
                  }
                />
                <Route path="/home" element={<SecondaryNavbar />} />
                <Route path="/blog" element={<Blog />} />
                <Route path="/testimonial" element={<Testimonial1 />} />
                <Route path="/landingpage" element={<LandingPage />} />
                <Route path="/coming-soon" element={<ComingSoon />} />
                <Route
                  path="/casedetails"
                  element={
                    <ProtectedRoute>
                      <CaseDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/talkaboutcase"
                  element={
                    <ProtectedRoute>
                      <ChatBotParent />
                    </ProtectedRoute>
                  }
                />
                <Route path="/faq" element={<Faq />} />
                <Route path="/pricing" element={<Pricing />} />
                <Route path="/Contactus" element={<ContactUs />} />
                <Route path="/Lawyerprofile" element={<LawyerProfile />} />
                <Route path="/select-court" element={<CourtSelection />} />
                <Route path="/high-court" element={<HighCourtPriviledge />} />

                {/* USER PROFILE */}
                <Route
                  path="/user-profile"
                  element={
                    <ProtectedRoute>
                      {/* <Profile /> */}
                      <UserProfile />
                    </ProtectedRoute>
                  }
                />

                {/* ALL CASES DASHBOARD */}
                {/* <Route path="/user-dashboard" element={<UserDashboard />} /> */}
                <Route
                  path="/all-cases-dashboard"
                  element={
                    <ProtectedRoute>
                      <AllCasesDashboard />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<Dashboard />} />
                  <Route path="cause-list" element={<CauseList />} />
                  <Route path="my-clients" element={<Clients />} />
                  <Route path="acts" element={<Acts />} />
                  {/* <Route path="alerts" element={<h1>Alerts</h1>} /> */}
                  <Route path="share" element={<Share />} />
                </Route>

                {/* All Cases Dashboard / High Court Delhi */}
                <Route
                  path="/all-cases-dashboard/high-court-delhi/:id"
                  element={
                    <ProtectedRoute>
                      <HighCourtDelhiDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                  <Route path="judge-analytics" element={<JudgeAnalytics />} />
                  <Route
                    path="opposing-counsel-analytics"
                    element={<OpposingCounselAnalytics />}
                  />
                  <Route path="similar-cases" element={<SimilarCases />} />
                </Route>

                {/* All Cases Dashboard / High Court Punjab-Haryana */}
                <Route
                  path="/all-cases-dashboard/high-court-punjab-haryana/:id"
                  element={
                    <ProtectedRoute>
                      <HighCourtPHDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* All Cases Dashboard / All High Court */}
                <Route
                  path="/all-cases-dashboard/all-high-court-cases/:id"
                  element={
                    <ProtectedRoute>
                      <AllHighCourtDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* All Cases Dashboard / Supreme Court */}
                <Route
                  path="/all-cases-dashboard/supreme-court/:id"
                  element={
                    <ProtectedRoute>
                      <SupremeCourtDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* All Cases Dashboard / District Court */}
                <Route
                  path="/all-cases-dashboard/district-court/:id"
                  element={
                    <ProtectedRoute>
                      <DistrictCourtDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* DELHI HIGH COURT ROUTE */}
                <Route
                  path="/high-court-delhi"
                  element={
                    <ProtectedRoute>
                      <HighCourtOfDelhi />
                    </ProtectedRoute>
                  }
                />
                {/* DELHI HIGH COURT CASE SUMMARY ROUTE */}
                <Route
                  path="/high-court-delhi/:id"
                  element={
                    <ProtectedRoute>
                      <HighCourtDelhiDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                  <Route path="judge-analytics" element={<JudgeAnalytics />} />
                  <Route
                    path="opposing-counsel-analytics"
                    element={<OpposingCounselAnalytics />}
                  />
                  <Route path="similar-cases" element={<SimilarCases />} />
                </Route>

                {/* <Route path="/ph_high-court" element={<PanjabAndHaraynaHC />} /> */}
                {/* Punjab & Haryana High Court */}
                <Route
                  path="/high-court-punjab-haryana"
                  element={
                    <ProtectedRoute>
                      <HighCourtOfPH />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/high-court-punjab-haryana/:id"
                  element={
                    <ProtectedRoute>
                      <HighCourtPHDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* ALL HIGH COURTS */}
                {/* <Route
                  path="/all-high-court-privilege"
                  element={<Allhighcourtprivildge />}
                /> */}
                <Route
                  path="/all-high-court-cases"
                  element={
                    <ProtectedRoute>
                      <AllHighCourt />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/all-high-court-cases/:id"
                  element={
                    <ProtectedRoute>
                      <AllHighCourtDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* District Courts */}
                {/* <Route path="/privilege" element={<PrivilegedContent />} /> */}
                <Route
                  path="/district-courts"
                  element={
                    <ProtectedRoute>
                      <DistrictCourts />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/district-courts/:id"
                  element={
                    <ProtectedRoute>
                      <DistrictCourtDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* Supreme Court */}
                {/* <Route
                  path="/supreme-court"
                  element={<SupremeCourtPrivilede />}
                /> */}
                <Route
                  path="/supreme-court"
                  element={
                    <ProtectedRoute>
                      <SupremeCourt />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/supreme-court/:id"
                  element={
                    <ProtectedRoute>
                      <SupremeCourtDetails />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<CaseSummary />} />
                  <Route path="timeline" element={<Timeline />} />
                  <Route path="complete-order-sheet" element={<OrderSheet />} />
                  <Route path="notes" element={<Notes />} />
                </Route>

                {/* JUDGEMENT SEARCH - MAIN PAGE ROUTE */}
                <Route
                  path="/judgement-search"
                  element={
                    <ProtectedRoute>
                      <LegalCaseSearch />
                    </ProtectedRoute>
                  }
                />

                <Route
                  path="/judgement-search/search"
                  element={<SearchResults />}
                />

                {/* JUDGEMENT SEARCH - SUMMARY ROUTE */}
                <Route
                  path="/judgement-search/:id"
                  element={<CaseSearchDetails />}
                />

                <Route
                  path="/high-court-case-details"
                  element={<HighCourtCaseDetails />}
                />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route
                  path="/terms-and-conditions"
                  element={<TermsAndConditions />}
                />
                <Route
                  path="/upload_judgement"
                  element={
                    <ProtectedRoute>
                      <UploadJudgement />
                    </ProtectedRoute>
                  }
                />
                <Route path="/wait-list" element={<WaitList />} />
                <Route path="/all-high-court" element={<Allhighcourt />} />
                <Route
                  path="/user-dashboard/:id"
                  element={<SupremeCoutDetail />}
                />
                <Route path="/about-us" element={<Aboutus />} />
                <Route
                  path="/password_reset/:otp/:token"
                  element={<PasswordReset />}
                />
              </Routes>
            </BrowserRouter>
          </CaseProvider>
        </FolderProvider>
      </NotificationProvider>
    </div>
  );
};

export default App;
