import React, { useState } from "react";
import { AppSidebar } from "./AppSidebar";
import { Outlet } from "react-router-dom";
import { Separator } from "../../components/ui/separator";
import {
  SidebarProvider,
  SidebarTrigger,
  SidebarInset,
} from "../../components/ui/sidebar";

const AllCasesDashboard = () => {
  const [getDataFromChild, setGetDataFromChild] = useState("");

  return (
    <SidebarProvider className="mt-20">
      <AppSidebar setGetDataFromChild={setGetDataFromChild} />
      <SidebarInset className="dark:bg-dark-600">
        <div className="flex h-16 shrink-0 items-center gap-2 transition-[width,height] ease-linear group-has-[[data-collapsible=icon]]/sidebar-wrapper:h-12">
          <div className="flex items-center gap-2 px-4">
            <SidebarTrigger />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <p className="font-semibold font-raleway">{getDataFromChild}</p>
          </div>
        </div>
        <div>
          <Outlet />
        </div>
      </SidebarInset>
    </SidebarProvider>
  );
};

export default AllCasesDashboard;
