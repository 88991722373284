// React Utils
import React, { useState } from "react";

// React Router
import { useNavigate } from "react-router-dom";

// React Icons
import { RiSearchLine } from "react-icons/ri";
import { IoMicOutline } from "react-icons/io5";
import { IoCloseOutline } from "react-icons/io5";

// Toastify Notifications
import { toast } from "react-toastify";

// Image
import SearchFile from "../../assets/images/LegalCaseSearch/SearchFile.png";

const LegalCaseSearch = () => {
  const [searchInput, setSearchInput] = useState(""); // String
  const [isListening, setIsListening] = useState(false); // Boolean

  const navigate = useNavigate();

  const handleSearchSubmit = async (e) => {
    e.preventDefault();
    navigate(`search?q=${searchInput}`);
  };

  // Handle Speech Recognition
  const handleSpeechRecognition = () => {
    if (!("webkitSpeechRecognition" in window)) {
      toast.error(
        "Speech recognition is not supported in this browser. Try using Chrome."
      );
      return;
    }

    const recognition = new window.webkitSpeechRecognition();
    recognition.lang = "en-US";
    recognition.continuous = false;
    recognition.interimResults = false;

    recognition.onstart = () => {
      let audioStart = new Audio("/StartRecording.mp3");
      audioStart.play();
      setIsListening(true);
    };

    recognition.onend = () => {
      let audioStop = new Audio("/StopRecording.mp3");
      audioStop.play();
      setIsListening(false);
    };

    recognition.onresult = (event) => {
      const speechResult = event.results[0][0].transcript;
      setSearchInput(speechResult);
    };

    recognition.onerror = (event) => {
      toast.error("Speech recognition error: " + event.error);
    };

    recognition.start();
  };

  return (
    <div
      className={`pt-40 pb-40 px-12 max-md:px-2 h-screen bg-white text-black dark:bg-dark-600 dark:text-gray-200 flex flex-col gap-10 items-center font-raleway bg-[url('/src/assets/images/LegalCaseSearch/SearchBackground.png')]`}
    >
      {/* Heading */}
      <div className="flex flex-col gap-4 text-center w-2/5 max-lg:w-full px-6">
        <img
          src={SearchFile}
          alt="search file"
          className="mx-auto mb-2 w-22 max-sm:w-20"
        />
        <h1 className="font-mulish font-bold text-4xl max-sm:text-2xl">
          Judgement Search
        </h1>
        <h4 className="font-raleway font-medium text-xl max-sm:text-base">
          Search for your Case and get the insights of your Court Room Trials
        </h4>
      </div>

      {/* Search Input */}
      <form
        className="flex max-sm:flex-col gap-3 w-2/3 max-md:w-full"
        onSubmit={handleSearchSubmit}
      >
        <div className="w-[85%] max-md:w-[75%] relative">
          <input
            type="text"
            className="px-4 py-4 max-sm:px-2 max-sm:py-2 max-sm:placeholder:text-xs w-full font-raleway font-semibold border border-[#d9d9d9] dark:placeholder:text-white/40 dark:bg-dark-450 dark:text-white dark:border-gray-600 rounded-lg "
            placeholder={
              isListening
                ? "Listening..."
                : "Search for “Case Number, Petitioner Name, Respondent Name”"
            }
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            required
            autoComplete="off"
          />
          {searchInput && (
            <IoCloseOutline
              onClick={() => setSearchInput("")}
              className="absolute right-20 text-2xl top-[50%] translate-y-[-50%] cursor-pointer"
            />
          )}
          <div className="absolute rounded-r-md flex gap-4 text-2xl border-l border-l-[#D9D9D9] dark:border-l-gray-600  top-[50%] translate-y-[-50%] py-4 px-4 right-1 bg-white dark:bg-dark-450">
            <IoMicOutline
              onClick={handleSpeechRecognition}
              className="cursor-pointer select-none"
            />
          </div>
        </div>
        {/* Search Button */}
        <div className="w-[15%] max-md:w-[25%]">
          <button className="flex font-raleway font-medium w-full px-4 py-[15px] bg-black dark:bg-light-600 dark:text-gray-700 items-center justify-center gap-2 text-white text-lg">
            <RiSearchLine className="font-black" />
            Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default LegalCaseSearch;
